var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    _vm._l(_vm.structs, function(struct) {
      return _c("BlockDayCell", {
        key: struct.cd,
        attrs: { "block-id": _vm.blockId, struct: struct }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-76bc3c30", { render: render, staticRenderFns: staticRenderFns })
  }
}