<template>
  <div class="candidate" @scroll="updateCalendarScroll">
    <table>
        <thead>
            <tr>
                <th :colspan="struct.rowSpan" v-for="struct in monthRepresentativeStructs" :key="struct.cd"><span>{{struct.date.format('YYYY年MM月')}}</span></th>
            </tr>
            <tr>
                <th v-for="struct in structs" :key="struct.cd" :class="{sat: struct.week === '土', sun: struct.week === '日'}">{{`${struct.date.format('D日')}(${struct.week})`}}</th>
            </tr>
        </thead>
        <tbody>
          <BlockRow :structs="structs" :block-id="blockId" v-for="blockId in blockIds" :key="blockId" />
        </tbody>
    </table>
  </div>
</template>

<script>
import $ from "jquery";
import { ReserveBlocks } from '../../../enums.js'
import BlockRow from './Candidate/BlockRow.vue';

export default {
  components: {BlockRow},
  props: {
    structs: Array, //[SchedulerDateStruct]
  },
  computed: {
    monthRepresentativeStructs(){
      return this.structs.filter((struct) => struct.rowSpan > 0);
    },
    blockIds(){
      return Object.keys(ReserveBlocks).map(id => Number(id))
    }
  },
  mounted(){
    this.updateCalendarScroll()
    const cellWidth = $(this.$el).find('td').outerWidth();
    this.$emit('updateCellWidth', cellWidth)
  },
  methods: {
    updateCalendarScroll(){
      const payload = {
        innerWidth: $(this.$el).width(),
        scrollWidth: this.$el.scrollWidth,
        scrolled: $(this.$el).scrollLeft(),
      }
      this.$emit('updateCalendarScroll', payload)
    }
  }
}
</script>

<style>

</style>