<template>
  <tr>
    <BlockDayCell v-for="struct in structs" :key="struct.cd" :block-id="blockId" :struct="struct" />
  </tr>
</template>

<script>
import BlockDayCell from './BlockRow/BlockDayCell.vue'
export default {
  components: {BlockDayCell},
  props: {
    structs: Array, // [SchedulerDateStruct]
    blockId: Number
  },
}
</script>

<style>

</style>