

//vues
import Vue from 'vue/dist/vue.esm.js';
import SearchSuggestForm from './search/SuggestForm.vue'
import SearchForm from './root/SearchForm.vue' 
import SearchFilterForm from './searchfilter/FilterForm.vue'
import ProposalScheduler from './proposal/Scheduler.vue'

import SignupForm from './signup/SignupForm.vue'
import SignupAuthForm from './signup/SignupAuthForm.vue'

import SigninForm from './signin/SigninForm.vue'
import SigninAuthForm from './signin/SigninAuthForm.vue'

import ReserveList from './reservelist/ReserveList.vue'

import Mixin from './mixin'

import Types from './types'

import Util from '../util'

const CommonMixin = {
  mounted(){
    Util.onVueMounted()
  }
}

//vue applications
export const vueConstructor = () => {
  if (document.querySelector('.efo-form')) {
    return {
      el: '.efo-form',
      mixins: [Mixin.EfoForm],
    }
  }
  if (document.querySelector('#vue_searchform')) {
    return {
      el: '#vue_searchform',
      mixins: [CommonMixin],
      components: {
        searchform: SearchForm,
        suggestform: SearchSuggestForm
      },
      data: {
        locationForm: Types.SearchLocationForm(),
        suggestOpen: false
      },
    }
  }
  if (document.querySelector('#vue_searchfilter')) {
    return {
      el: '#vue_searchfilter',
      mixins: [CommonMixin],
      components: {
        filterform: SearchFilterForm,
        suggestform: SearchSuggestForm
      },
      data: {
        locationForm: Types.SearchLocationForm(),
        suggestOpen: false
      }
    }
  }
  if (document.querySelector('#vue_scheduler')) {
    return {
      el: '#vue_scheduler',
      mixins: [CommonMixin],
      components: {
        scheduler: ProposalScheduler
      }
    }
  }
  if (document.querySelector('#vue_signup')) {
    return {
      el: '#vue_signup',
      mixins: [CommonMixin],
      components: {
        signupform: SignupForm
      }
    }
  }
  if (document.querySelector('#vue_signup_auth')) {
    return {
      el: '#vue_signup_auth',
      mixins: [CommonMixin],
      components: {
        signupauthform: SignupAuthForm
      }
    }
  }
  if (document.querySelector('#vue_signin')) {
    return {
      el: '#vue_signin',
      mixins: [CommonMixin],
      components: {
        signinform: SigninForm
      }
    }
  }
  if (document.querySelector('#vue_signin_auth')) {
    return {
      el: '#vue_signin_auth',
      mixins: [CommonMixin],
      components: {
        signinauthform: SigninAuthForm
      }
    }
  }
  if (document.querySelector('#vue_reserve_list')) {
    return {
      el: '#vue_reserve_list',
      mixins: [CommonMixin],
      components: {
        reservelist: ReserveList
      }
    }
  }
  return null
}

export const createVueApp = (constructor) => {
  return new Vue(constructor)
}
