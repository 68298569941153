<template>
	<form class="login_code" novalidate data-validate @submit="willUpdate" @change="updateStatus" data-nextrequired-flag="1">
    <section class="customer_info sms-confirm ml-auto mr-auto mb-0">
      <h4 class="h4-ttl ml-0">認証コードを入力</h4>
      <p class="mb-4">SMS（ショートメッセージ）で受け取った認証コード（4桁の番号）を入力してください。</p>
      <div class="form-group pl-0 pr-0 mb-0">
        <label for="sms-code" class="mb-0 tel required" :class="{ok: inputs_status.smscode}">認証コード</label>
        <input type="tel" data-type="smscode" name="smscode" maxlength="4" id="sms-code" v-model="smscode" class="form-control alert" :class="{'alert-danger': !inputs_status.smscode}" required>
        <p class="error-text" v-if="validation_errors.smscode" v-cloak>{{validation_errors.smscode}}</p>
      </div>
      <p class="text-info mt-3"><button class="sms_modal" type="button" @click.prevent.stop="showModal('#modal2')"><i class="fas fa-exclamation-triangle fa-fw mr-1"></i>SMSが届かない場合</button></p>
      <div class="w-100 text-center mt-40 mb-10">
        <button class="btn suggest btn_login" :disabled="!submit_active">認証する</button>
      </div>
    </section>
  </form>
</template>

<script>
import Mixin from '../mixin'
import { InvalidError, ForbiddenError } from '../enums'
import API from '../api'
import Util from '../../util'
export default {
  mixins: [Mixin.EfoForm],
  data(){
    return {
      smscode: '',
    }
  },
  mounted(){
    console.log('register_auth_form mounted')
  },
  methods: {
    showModal(selector){
      Util.showModal(selector)
    },
    willUpdate(event){
      event.preventDefault();
      if(this.locked) {
        return false;
      }

      if(!this.validate()){
        event.preventDefault();

        // 不備のある項目の先頭にフォーカスを当てる
        let item = this.inputs.find((input)=> {return input.error;});
        item.element.focus();
        //console.log('NG', this.validation_errors);
        return false;
      }
      this.locked = true;
      Util.showModal('#js_modal_loading').then(() => {
				return API.postSigninAuth({sms_code: this.smscode})
      })
      .then(success => {
        console.log(success, 'success')
        if (success) {
          window.location.href = '/mypage/top'
          return
        }
        alert('エラーが発生しました')
      }).catch(e => {
      	this.locked = false;
				if (e instanceof InvalidError) {
          console.log(e.code)
					Util.hideModal('#js_modal_loading').then(() => {
            switch (e.code) {
              case 'NOTFOUND':
              case 'INVALID':
                Util.showModal('#modal3')
                return
              case 'OVERCOUNT':
                Util.showModal('#modal4')
                return 
              case 'EXPIRE':
                Util.showModal('#modal5')
                return 
              default:
                alert('認証に失敗しました')
								break;
            }
          })
				}else{
          console.log(e)
          alert('エラーが発生しました')
        }
      })
      return false
    }
  }
}
</script>

<style>

</style>