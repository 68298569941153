<template>
  <form action="/search" class="is-horizontal search-form pb-0 mb-0" novalidate>
    <div class="search-options mb-0">

      <div class="search-options-header">
        <div><h1><span class="text-bold">{{baseParams.address}}<span v-if="isSeo">・{{baseParams.schoolYearText}}</span>の学習塾・予備校</span></h1></div>
        <div>距離：<span class="text-mr">{{baseParams.radiusText}}以内</span>学年：<span class="text-mr">{{baseParams.schoolYearText}}<br></span>授業：<span>{{baseParams.teachCourseText}}</span></div>
        <div class="overflow" v-if="baseParams.optionTexts.length > 0">こだわり：<span v-for="(optionText,index) in baseParams.optionTexts" :key="optionText"><span v-if="index > 0">、</span>{{optionText}}</span></div>
      </div>
      <div class="collapse" id="search-options_outside">
        <div class="search p-0">
          <section class="top_search_section">
            <div class="form-group field search-input flex-wrap">
              <label for="address" class="mb-0 address required p-0 text-bold">場所</label>
              <input type="hidden" name="address" :value="locationForm.address" />
              <input type="hidden" name="keyword" :value="locationForm.keyword" />
              <input type="hidden" name="station_id" :value="locationForm.stationId" v-if="locationForm.stationId" />
              <input type="hidden" name="user_location_lat" :value="locationForm.userLocation.latitude" v-if="locationForm.userLocation" />
              <input type="hidden" name="user_location_lng" :value="locationForm.userLocation.longitude" v-if="locationForm.userLocation" />
              <input type="text" class="form-control alert"
              :value="locationForm.address" 
              @focus.prevent.stop="willSuggest"
              readonly="readonly"
              :class="{
                valid: !errors.location,
                'error-text': errors.location,
              }" style="width: 263px;" role="alert" placeholder="駅・地名を入力">
              <span id="address-error" class="error-text" v-if="errors.location">{{errors.location}}</span>
              <div class="search-distance">
                <span>から</span>
                <div class="cp_ipselect cp_sl02">
                  <select name="radius" v-model="params.radius" class="form-control">
                    <option :value="val" v-for="(label, val) in SearchRadius" :key="val">{{label}}</option>
                  </select>
                </div>
                <span>以内</span>
              </div>
            </div>
            <div class="form-group search-radius school_year_area" style="align-items: baseline;">
              <label for="school_year" class="mb-0 required p-0 text-bold school_format_ttl">学年</label>
              <div class="cp_ipselect cp_sl01">
                <select id="school_year" name="school_year" v-model="params.schoolYear" class="form-control alert mb-0 unselected" :class="{
                    unselected: !params.schoolYear,
                    'error-text': errors.schoolYear,
                  }" style="width: 256px;" role="alert">
                  <option value="" disabled="disabled">選択してください</option>
                  <option :value="val" v-for="(label, val) in SearchSchoolYears" :key="val">{{label}}</option>
                </select>
                <span id="school_year-error" class="error-text" v-if="errors.schoolYear">{{errors.schoolYear}}</span>
              </div>
            </div>
            <hr class="none">
            <div class="form-group search-radius customer_info sms-confirm">
              <label class="form-check-label required pl-2 text-bold school_format_ttl w-80">授業形式</label>
              <div class="form-check-inline" id="school_format_group">
                <input type="checkbox" class="form-check-input" id="format1" name="teach_course_group" value="1" v-model="params.teachCourseGroup">
                <label class="form-check-label text-left sms-check" for="format1"><span class="f-normal">集団指導</span></label>
                <input type="checkbox" class="form-check-input ml-3" id="format2" name="teach_course_individual" value="1" v-model="params.teachCourseIndividual">
                <label class="form-check-label text-left sms-check ml-3" for="format2"><span class="f-normal">個別指導</span></label>
              </div>
              <span id="school_format[]-error" class="error-text" v-if="errors.schoolFormats">{{errors.schoolFormats}}</span>
            </div>
            
            <div class="form-group search-radius customer_info sms-confirm">
              <label class="form-check-label required p-0 text-bold school_format_ttl online_format">オンライン</label>
              <div class="form-check-inline">
                <input type="checkbox" class="form-check-input ml-3" id="format3" name="online_support" value="1" v-model="onlineSupportAlt">
                <label class="form-check-label text-left sms-check" for="format3"><span class="f-normal">映像授業・ライブ授業</span></label>
                  <span class="ml-2 online_tip" data-toggle="tooltip" title="映像授業、双方向ライブ授業（ZOOMなど）など、オンラインでも受講できる仕組みがされている塾に絞り込みます" data-placement="bottom" ><img src="/assets/icon_qa.svg" alt="？"></span>
              </div>
            </div>
            <hr>
          </section>
          <section class="top_search_section flex-column">
            <div class="search-options_inside_btn">
              <a data-toggle="collapse" href="#search-options_inside" aria-expanded="false" aria-controls="search-options_inside" class="form-check-label text-left text-bold collapsed">こだわり条件で絞り込み</a>
            </div>
            <div id="search-options_inside" class="mb-2 mt-2 additional_items collapse">
              <h6 class="text-bold">目的</h6>
              <ul class="inside_list">
                <li v-for="(label, val) in SearchPurposeLists" :key="val">
                  <div class="form-group search-radius customer_info sms-confirm">
                    <div class="form-check-inline">
                      <input type="checkbox" class="form-check-input ml-3" :id="val" name="purpose_lists[]" v-model="params.purposeLists" :value="val">
                      <label class="form-check-label text-left sms-check" :for="val">
                        <span class="f-normal">{{label}}</span>
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
              <h6 class="text-bold">学習サポート</h6>
              <ul class="inside_list">
                <li v-for="(label, val) in SearchSupports" :key="val">
                  <div class="form-group search-radius customer_info sms-confirm">
                    <div class="form-check-inline">
                      <input type="checkbox" class="form-check-input ml-3" :id="val" name="supports[]" v-model="params.supports" :value="val">
                      <label class="form-check-label text-left sms-check" :for="val"><span class="f-normal">{{label}}</span></label>
                    </div>
                  </div>
                </li>
              </ul>
              <h6 class="text-bold">オンライン</h6>
              <ul class="inside_list">
                <li>
                  <div class="form-group search-radius customer_info sms-confirm">
                    <div class="form-check-inline">
                      <input type="checkbox" class="form-check-input ml-3" id="online1" name="movie_flg" value="1" v-model="movieFlgAlt">
                      <label class="form-check-label text-left sms-check" for="online1"><span class="f-normal">映像授業</span></label>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="form-group search-radius customer_info sms-confirm">
                    <div class="form-check-inline">
                      <input type="checkbox" class="form-check-input ml-3" id="online2" name="zoom_flg" value="1" v-model="zoomFlgAlt">
                      <label class="form-check-label text-left sms-check" for="online2"><span class="f-normal">双方向ライブ授業</span></label>
                    </div>
                  </div>
                </li>
              </ul>
              <h6 class="text-bold">設備</h6>
              <ul class="inside_list">
                <li v-for="(label, val) in SearchFacilities" :key="val">
                  <div class="form-group search-radius customer_info sms-confirm">
                    <div class="form-check-inline">
                      <input type="checkbox" class="form-check-input ml-3" :id="val" name="facilities[]" v-model="params.facilities" :value="val">
                      <label class="form-check-label text-left sms-check" :for="val"><span class="f-normal">{{label}}</span></label>
                    </div>
                  </div>
                </li>
              </ul>
              <hr>
            </div><!--/additional_items-->
          </section>
          <div class="search-request">
            <div class="search-button text-center"><button id="search_button" class="btn btn_reservelist_list js-submit" type="button" @click="willUpdate">検索</button></div>
          </div>
        </div><!--/search-->
      </div>
    </div><!--/search-options-->
    <div class="search-options_footer_btn">
      <a 
        @click.prevent="toggleOutside"
        data-toggle="collapse" href="#search-options_outside"
        aria-expanded="false" aria-controls="search-options_outside"
        class="text-bold collapsed" >{{openOutside ? '閉じる' : '再検索／絞り込み'}}</a>
    </div>
  </form>
</template>

<script>
import Types from '../types'
import {SearchSchoolYears, SearchRadius, SearchPurposeLists, SearchSupports, SearchFacilities} from '../enums'
import Util from '../../util'

export default {
  props: ['locationForm', 'isSeo'],
  data(){
    return {
      openOutside: false,
      baseParams: Types.SearchSchoolParams(),
      params: Types.SearchSchoolParams(),
      submited: false
    }
  },
  created(){
    console.log('FilterForm creted')
    if (window.params) {
      this.baseParams.fill(window.params)
      this.params.fill(window.params)
      this.locationForm.fill(window.params)
    }
  },
  mounted(){
    console.log('FilterForm mounted')
    window.onunload = this.onUnload
  },
  computed: {
    SearchSchoolYears: () => SearchSchoolYears,
    SearchRadius: () => SearchRadius,
    SearchPurposeLists: () => SearchPurposeLists,
    SearchSupports: () => SearchSupports,
    SearchFacilities: () => SearchFacilities,
    onlineSupportAlt:{
      get(){return this.params.onlineSupport},
      set(val){
        this.params.onlineSupport = val
        this.params.movieFlg = val
        this.params.zoomFlg = val
      }
    },
    movieFlgAlt:{
      get(){return this.params.movieFlg},
      set(val){
        this.params.movieFlg = val
        if (this.params.movieFlg == this.params.zoomFlg) {
          this.params.onlineSupport = val
        }
      }
    },
    zoomFlgAlt:{
      get(){return this.params.zoomFlg},
      set(val){
        this.params.zoomFlg = val
        if (this.params.movieFlg == this.params.zoomFlg) {
          this.params.onlineSupport = val
        }
      }
    },
    invalids(){
      let ret = {}
      Object.assign(ret, this.params.validate())
      Object.assign(ret, this.locationForm.validate())
      return ret
    },
    errors(){
      return this.submited ? this.invalids : {}
    }
  },
  methods: {
    willSuggest(){
      this.$emit('opensuggest')
      Util.showModal('#modal_search');
    },
    willUpdate () {
      this.submited = true
      if (Object.keys(this.errors).length) {
        return false
      }
      Util.showModal('#js_modal_loading').then(() => {
        this.$el.submit()
      })
    },
    onUnload(){
      console.log('onUnload')
      this.submited = false
      Util.hideModal('#js_modal_loading', true)
    },
    toggleOutside(){
      this.openOutside = !this.openOutside
    }
  }
}
</script>
