<template>
  <form action="/search" method="GET" novalidate>
      <section class="top_search_section">
          <div class="form-group field search-input">
              <label for="address" class="mb-0 address required p-0 text-bold">場所<img src="/assets/ill_map.svg" alt="map" class="map"></label>
              <input type="hidden" name="address" :value="locationForm.address" />
              <input type="hidden" name="keyword" :value="locationForm.keyword" />
              <input type="hidden" name="station_id" :value="locationForm.stationId" v-if="locationForm.stationId" />
              <input type="hidden" name="user_location_lat" :value="locationForm.userLocation.latitude" v-if="locationForm.userLocation" />
              <input type="hidden" name="user_location_lng" :value="locationForm.userLocation.longitude" v-if="locationForm.userLocation" />
              <input type="text" class="form-control alert" 
              :value="locationForm.address" 
              @focus.prevent.stop="willSuggest"
              readonly="readonly"
              :class="{
                valid: !highlights.location,
                'error-text': highlights.location,
              }" style="width: 263px;" role="alert" placeholder="駅・地名を入力">
              <span id="address-error" class="error-text" v-if="errors.location">{{errors.location}}</span>
          </div>
          <div class="form-group search-radius">
              <label for="school_year" class="mb-0 required p-0 text-bold school_format_ttl text-center">学年</label>
              <div class="cp_ipselect cp_sl01">
                  <select id="school_year" name="school_year" v-model="params.schoolYear" class="form-control alert mb-0" :class="{
                    unselected: !params.schoolYear,
                    'error-text': highlights.schoolYear,
                  }" style="width: 256px;" role="alert">
                      <option value="" disabled="disabled" selected>選択してください</option>
                      <option :value="val" v-for="(label, val) in SearchSchoolYears" :key="val">{{label}}</option>
                  </select>
                  <span id="school_year-error" class="error-text" v-if="errors.schoolYear">{{errors.schoolYear}}</span>
              </div>
          </div>
          <hr>
          <div class="form-group search-radius customer_info sms-confirm">
              <label class="form-check-label required pl-2 text-bold school_format_ttl w-80 text-center">授業形式</label>
              <div class="form-check-inline" id="school_format_group">
                  <input type="checkbox" class="form-check-input" id="format1" name="teach_course_group" value="1" v-model="params.teachCourseGroup">
                  <label class="form-check-label text-left sms-check" for="format1"><span class="f-normal">集団指導</span></label>
                  <input type="checkbox" class="form-check-input ml-3" id="format2" name="teach_course_individual" value="1" v-model="params.teachCourseIndividual">
                  <label class="form-check-label text-left sms-check ml-3" for="format2"><span class="f-normal">個別指導</span></label>
              </div>
              <span id="school_format[]-error" class="error-text" v-if="errors.schoolFormats">{{errors.schoolFormats}}</span>
          </div>
          <div class="form-group search-radius customer_info sms-confirm">
              <label class="form-check-label required p-0 text-bold school_format_ttl text-center w-80">オンライン</label>
              <div class="form-check-inline">
                  <input type="checkbox" class="form-check-input ml-3" id="format3" name="online_support" value="1" v-model="params.onlineSupport">
                  <label class="form-check-label text-left sms-check" for="format3"><span class="f-normal">映像授業・ライブ授業</span></label>
                  <span class="ml-2 online_tip" data-toggle="tooltip" title="映像授業、双方向ライブ授業（ZOOMなど）など、オンラインでも受講できる仕組みがされている塾に絞り込みます" data-placement="bottom"><img src="/assets/icon_qa.svg" alt="？"></span>
              </div>
          </div>
          <hr>
      </section>
      <div class="search-button text-center"><button id="search_button" class="btn btn_reservelist_list js-submit" type="button" @click="willUpdate"><img src="/assets/icon_search.svg" alt=""> 塾を検索</button></div>
  </form>
</template>

<script>
import Types from '../types'
import {SearchSchoolYears} from '../enums'
import Util from '../../util'

export default {
  props: ['locationForm'],
  computed: {
    SearchSchoolYears: () => SearchSchoolYears,
    invalids(){
      let ret = {}
      Object.assign(ret, this.params.validate())
      Object.assign(ret, this.locationForm.validate())
      return ret
    },
    errors(){
      return this.submited ? this.invalids : {}
    },
    highlights(){
      return {
        location: !!this.invalids.location,
        schoolYear: !!this.errors.schoolYear || (!this.invalids.location && this.invalids.schoolYear)
      }
    }
  },
  data(){
    return {
      params: Types.SearchSchoolParams(),
      submited: false
    }
  },
  created(){
    console.log('SearchForm created')
    if (window.params) {
      this.params.fill(window.params)
      this.locationForm.fill(window.params)
    }
  },
  mounted(){
    console.log('SearchForm mounted')
    window.onunload = this.onUnload
  },
  methods: {
    willSuggest(){
      this.$emit('opensuggest')
      Util.showModal('#modal_search');
    },
    willUpdate () {
      this.submited = true
      if (Object.keys(this.errors).length) {
        return false
      }
      Util.showModal('#js_modal_loading').then(() => {
        this.$el.submit()
      })
    },
    onUnload(){
      console.log('onUnload')
      this.submited = false
      Util.hideModal('#js_modal_loading', true)
    },
  }
}
</script>

<style>

</style>