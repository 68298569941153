<template>
  <ReserveCalendarWrapper :structs="structs" />
</template>

<script>
import $ from 'jquery'
import ReserveCalendarWrapper from './Scheduler/ReserveCalendarWrapper.vue'
import moment from 'moment'
import Types from '../types'
import API from '../api'
import Util from '../../util'

export default {
  components: {ReserveCalendarWrapper},
  props: {
    is_from_back_mypage: Number,
    is_first_proposal: Number
  },
  computed: {
    isFirstProposal () {
      return !!parseInt(this.is_first_proposal)
    },
    isFromBackMypage () {
      return !!parseInt(this.is_from_back_mypage)
    },
    currentParams(){
      return this.structs.map(struct => struct.params()).reduce((acc, val) => acc.concat(val), []);
    },
    hasActiveBlocks(){
      return this.structs.some((struct) => struct.hasActiveBlock)
    },
    hasChange(){
      return JSON.stringify(this.currentParams) !== this.baseParams
    },
    updateText(){
      if (!this.hasBaseActiveBlock) {
        return '希望日を登録する'
      }
      if (this.isFromBackMypage) {
        return '希望日を変更する'
      }
      return this.hasChange ? '日程を変更して送信する' : '登録済の日程を送信する'
    },
    shouldUpdate(){
      if (!this.synced) {
        return false
      }
      if (this.hasChange) {
        return true
      }
      if (!this.isFromBackMypage && this.hasBaseActiveBlock) {
        return true
      }
      return false
    }
  },
  watch: {
    synced: function(newValue, oldValue){
      $('#js_footer_button_wrapper').show();
      $('#js_btn_back_mypage').toggle(this.isFromBackMypage);
      $('#js_btn_update_schedule').prop("disabled", !this.shouldUpdate);
      $('#js_btn_update_schedule').text(this.updateText);
      Util.schedulerFooterCtrl()
    },
    shouldUpdate: function(newValue, oldValue){
      $('#js_btn_update_schedule').prop("disabled", !newValue);
    },
    updateText: function(newValue, oldValue){
      $('#js_btn_update_schedule').text(newValue);
    },
  },
  data () {
    return {
      synced: false,
      structs: [],
      hasBaseActiveBlock: false,
      baseParams: null,
      updating: false,
    }
  },
  created () {
    let now = new Date().getHours();
    console.log(now)
    if (now >= 0 && now <= 12) {
      var minStartAt = moment().set('hour', 12).add(360, 'minutes')
    } else if(now >= 13 && now <= 20) {
      var minStartAt = moment().set('hour', 12).add(1,'days')
    } else if(now >= 21 && now <= 23) {
      var minStartAt = moment().set('hour', 12).add({days : 1, minutes : 360 })
    }
    console.log('calender.vue')
    var mt = moment().startOf('day')
    var monthTopStruct = null
    const last = moment(mt).add(30, 'days')
    while (mt.isSameOrBefore(last, 'day')) {
      const struct = Types.SchedulerDateStruct(moment(mt))
      struct.setOutDated(minStartAt)
      if (!monthTopStruct || monthTopStruct.rowCd !== struct.rowCd) {
        monthTopStruct = struct
      }
      monthTopStruct.rowSpan += 1
      this.structs.push(struct)
      mt.add(1, 'days')
    }
    API.fetchObserveBlocks().then(blocks => {
      this.structs.forEach(struct => {
        struct.setStates(blocks.filter(b => b.cd === struct.cd))
      })
      this.hasBaseActiveBlock = this.structs.some((struct) => struct.hasActiveBlock)
      this.baseParams = JSON.stringify(this.currentParams)
      this.synced = true
    })
  },
  mounted () {
    this.$root.$on('willUpdate', this.willUpdate);
    if (this.isFirstProposal) {
		  Util.showModal('#flow') 
    }
  },
  methods: {
    willBack() {
      window.location.href = '/mypage/top'
    },
    willUpdate () {
      console.log('willUpdate')
      if (this.updating) {
        return
      }
      if (!this.shouldUpdate) {
        return
      }
      try {
        this.execUpdate()
      }
      catch (e) {
        console.log(e)
        alert(e.message)
      }
    },
    execUpdate () {
      console.log('execUpdate')
      if (this.updating) {
        return
      }
      this.updating = true
      const proposal_params = this.currentParams
      Util.showModalSending().then(() => {
        return API.updateObserveBlocks({proposal_params})
      })
      .then(Util.hideModalSending)
      .then(() => {
        if (this.hasActiveBlocks) {
          Util.showModal('#modal3')
        }else{
          Util.showModal('#modal4')
        }
      }).catch(e => {
        console.log(e)
        this.updating = false
        alert('エラーが発生しました')
      })
    }
  }
}
</script>

<style >
.calendar table td span.no-deta {
  display: inline-block;
  height: 45px;
}
</style>