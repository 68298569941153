import axios from 'axios'
import Types from './types'
import { InvalidError, ForbiddenError } from './enums'

const API = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=utf-8'
  }
})

API.interceptors.response.use(response => response, (error) => {
  if (error.response) {
    if (error.response.status === 400) {
      throw new InvalidError(error.response.data.code)
    }
    if (error.response.status === 403) {
      // router.push({ name: 'login' })
      throw new ForbiddenError()
    }
  }
  throw new Error('unknown')
})
export default {
  postSignUp: async params => API.post(`/api/v1/observe/signup.json`, {signup: params}).then(r => r.status === 201),
  postSignUpAuth: async params => API.post(`/api/v1/observe/signup_auth.json`, {auth: params}).then(r => r.status === 201),
  postSignIn: async params => API.post(`/api/v1/observe/signin.json`, {signin: params}).then(r => r.status === 201),
  postSigninAuth: async params => API.post(`/api/v1/observe/signin_auth.json`, {auth: params}).then(r => r.status === 201),
  fetchObserveBlocks: async () => API.get('/api/v1/observe/user_blocks.json').then(r => (r.data.observe_user_blocks || []).map(data => Types.ObserveUserBlock(data))),
  updateObserveBlocks: async params => API.post(`/api/v1/observe/user_blocks.json`, params).then(r => r.status === 201),
  fetchProposalSchools: async () => API.get('/api/v1/observe/user_block_schools.json').then(r => (r.data || []).map(data => Types.ProposalSchool(data))),
  removeProposalSchool: async school_id => API.delete(`/api/v1/observe/user_block_schools/${school_id}.json`).then(r => r.status === 204),
  searchStations: async keyword => API.get(`/api/v1/stations.json`, {params: {keyword}}).then(r => r.data),
  selectReserve: async (school_id, block_school_id) => API.get(`/mypage/select/${school_id}/decide/${block_school_id}`).then(r => r.data),
  deleteSchool: async (school_id) => API.get(`/mypage/top/delete/school/${school_id}`).then(r => r.data)
}
