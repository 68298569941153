<template>
  <td v-if="onLoading"><label></label></td>
  <td :class="style" v-else>
    <label v-if="completed">
      <span>予約済</span>
    </label>
    <label v-else-if="outdated"></label>
    <label v-else>
      <input type="checkbox"
        :disabled="outdated"
        :value="blockId"
        v-model="struct.selected"
        ><span v-if="adjusting">調整中</span><span v-else></span>
    </label>
  </td>
</template>

<script>
import { ReserveBlocks } from '../../../../../enums.js'
import moment from 'moment'
export default {
  props: {
    blockId: Number,
    struct: Object, // SchedulerDateStruct
  },
  computed: {
    onLoading() {
      return !this.struct.syncd
    },
    outdated() {
      return this.struct.outdated.includes(this.blockId)
    },
    style(){
      return {
        over: this.outdated,
        sat: this.struct.week === '土',
        sun: this.struct.week === '日',
        adjusting: this.adjusting,
        reserved: this.completed,
      }
    },
    isChecked(){
      return this.struct.selected.includes(this.blockId)
    },
    adjusting(){
      return this.isChecked && this.struct.baseSelected.includes(this.blockId)
    },
    completed(){
      return this.struct.completeds.includes(this.blockId)
    }
  }
}
</script>

<style>

</style>