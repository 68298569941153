<template>
		<form id="efoForm" class="efo-form customer_regist_confirm" action="" method="" novalidate data-validate @submit="willUpdate" @change="updateStatus" data-nextrequired-flag="1">
				<h4 class="h4-ttl ml-0">認証コードを入力</h4>
				<p class="ttl-subtext">SMS（ショートメッセージ）で受け取った認証コード（４桁の番号）を入力してください。</p>
				<div class="customer_regist_form">
						<label for="sms-code" class="mb-0 tel required" :class="{ok: inputs_status.smscode}">認証コード</label>
					<div class="form-group pl-0 pr-0 mb-0">
						<div class="input-group">
						<input type="tel" data-type="smscode" name="smscode" maxlength="4" placeholder="例）0000" id="sms-code" v-model="smscode" class="form-control size_m alert" :class="{'alert-danger': !inputs_status.smscode}" required>
						</div>
						<p class="error-text" v-if="validation_errors.smscode" v-cloak>{{validation_errors.smscode}}</p>
					</div>
					<p class="text-info pc"><button type="button" data-toggle="modal" data-target="#modal2"><img src="/assets/icon_exclamation.svg" alt="！">SMSが届かない場合</button></p>
				</div>
			<div class="sms-confirm">
				<p class="text-info sp"><button type="button" @click.prevent.stop="showModal('#modal2')"><i class="fas fa-exclamation-triangle fa-fw mr-1"></i>SMSが届かない場合</button></p>
				<div class="check_area">
						<div class="pl-0">
								<input type="checkbox" id="sms-check" name="sms-check" v-model="smsCheck" class="sms-check all" required>
								<label for="sms-check" class="sms-check"></label>
						</div>
						<div class="pl-0">
								<p class="mb-0 palt">「<a href="/terms" target="_blank">利用規約</a>」「<a href="https://www.jiem.co.jp/privacy/" target="_blank">プライバシーポリシー</a>」に同意する。</p>
						</div>
				</div>
				<div class="w-100 text-center mt-3 mb-10">
						<button id="submit-entry2" class="btn col-md-6 col-11 suggest2 btn_confirm" :disabled="!submit_active"><span class="small">ここまでの情報を登録して</span><br>体験希望日の日程選択へ</button>
				</div>
			</div>
		</section>

		<section class="sms-confirm ml-auto mr-auto">
				<h4 class="h4-ttl ml-0">入力情報の確認</h4>
				<slot/>
				<p class="text-right mb-5"><button type="button" class="btn btn-sm ml-auto back_regist" onclick="location.href = '/customer/regist';">情報を修正する</button></p>
					<div class="check_area">
						<div class="pl-0">
								<input type="checkbox" id="sms-check2" name="sms-check" v-model="smsCheck" class="sms-check all" required>
								<label for="sms-check" class="sms-check"></label>
						</div>
						<div class="pl-0">
								<p class="mb-0 palt">「<a href="/terms" target="_blank">利用規約</a>」「<a href="https://www.jiem.co.jp/privacy/" target="_blank">プライバシーポリシー</a>」に同意する。</p>
						</div>
				</div>
				<div class="w-100 text-center mt-3 mb-10">
						<button id="submit-entry2" class="btn col-md-6 col-11 suggest2 btn_confirm" :disabled="!submit_active"><span class="small">ここまでの情報を登録して</span><br>体験希望日の日程選択へ</button>
				</div>
		</section>
		</form>
	</section>
</template>

<script>
import Mixin from '../mixin'
import { InvalidError, ForbiddenError } from '../enums'
import API from '../api'
import Util from '../../util'
export default {
  mixins: [Mixin.EfoForm],
  data(){
    return {
			smscode: '',
			smsCheck: false,
    }
  },
  mounted(){
    console.log('register_auth_form mounted')
  },
  methods: {
    showModal(selector){
      Util.showModal(selector)
    },
    willUpdate(event){
      event.preventDefault();
      if(this.locked) {
        return false;
      }

      if(!this.validate()){
        event.preventDefault();

        // 不備のある項目の先頭にフォーカスを当てる
        let item = this.inputs.find((input)=> {return input.error;});
        item.element.focus();
        //console.log('NG', this.validation_errors);
        return false;
      }
			this.locked = true;
      Util.showModal('#js_modal_loading').then(() => {
				return API.postSignUpAuth({sms_code: this.smscode})
			})
			.then(success => {
        console.log(success, 'success')
        if (success) {
          window.location.href = '/proposal/scheduler'
          return
        }
        alert('エラーが発生しました')
      }).catch(e => {
      	this.locked = false;
				if (e instanceof InvalidError) {
					console.log(e.code)
					Util.hideModal('#js_modal_loading').then(() => {
						switch (e.code) {
							case 'NOTFOUND':
							case 'INVALID':
								Util.showModal('#modal3')
								return
							case 'OVERCOUNT':
								Util.showModal('#modal4')
								return
							case 'EXPIRE':
								Util.showModal('#modal5')
								return
							default:
								alert('認証に失敗しました')
								break;
						}
					})
				}else{
					console.log(e)
					alert('エラーが発生しました')
				}
      })
      return false
    }
  }
}
</script>

<style>

</style>
