var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "login_sms",
      attrs: {
        id: "customer_info",
        "data-validate": "",
        novalidate: "",
        "data-remains": _vm.remain_required,
        "data-nextrequired-flag": "1"
      },
      on: { submit: _vm.willUpdate, change: _vm.updateStatus }
    },
    [
      _c("h4", { staticClass: "h4-ttl ml-0" }, [_vm._v("ご本人確認")]),
      _vm._v(" "),
      _c("p", { staticClass: "mb-4" }, [
        _vm._v(
          "ご本人確認のため、ご登録済みの携帯電話番号にSMS（ショートメッセージ）で認証コード（SMS認証）をお送りします。"
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c(
          "label",
          {
            staticClass: "mb-0 tel required",
            class: { ok: _vm.inputs_status.tel },
            attrs: { for: "tel" }
          },
          [_vm._v("携帯電話番号")]
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.tel,
              expression: "tel"
            }
          ],
          staticClass: "form-control alert",
          class: { "alert-danger": !_vm.inputs_status.tel },
          attrs: {
            type: "tel",
            id: "tel",
            name: "tel",
            "data-type": "tel",
            role: "alert",
            placeholder: "例)08012345678",
            maxlength: "11",
            required: ""
          },
          domProps: { value: _vm.tel },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.tel = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _vm.validation_errors.tel
          ? _c("p", { staticClass: "error-text" }, [
              _vm._v(_vm._s(_vm.validation_errors.tel))
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "w-100 text-center mt-40 mb-10" }, [
        _c(
          "button",
          {
            staticClass: "btn suggest2 btn_code",
            attrs: { id: "sms-send", disabled: !_vm.submit_active }
          },
          [_vm._v("認証コードを送信する")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2f2a89ce", { render: render, staticRenderFns: staticRenderFns })
  }
}