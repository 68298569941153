<template>
  <form id="modal_search_form" action name="modal_search">
    <div class="search p-0">
      <section class="top_search_section">
        <div class="form-group field search-input flex-wrap">
          <input
            ref="input"
            type="text"
            id="keywords"
            name="keywords"
            class="form-control alert"
            role="alert"
            placeholder="駅・地名を入力"
            autocomplete="off"
            v-model="keywordAlt"
            @keydown.enter.prevent.stop="didEnter"
            @compositionstart="composing=true"
            @compositionend="composing=false"
            @keydown.up.prevent.stop="listUp"
            @keydown.down.prevent.stop="listDown"
          />
          <button type="button" class="btn_reset" @click.prevent.stop="reset" v-if="keywordAlt.length">
            <img src="/assets/icon_reset.svg" alt="リセット" />
          </button>
          <button class="cancel" @click.prevent.stop="close">キャンセル</button>
        </div>
        <div class="present_location" v-if="shouldUserLocation">
          <button @click.prevent.stop="useUserLocation">
            <img src="/assets/icon_genzaichi.svg" alt="現在地" />{{userLocationLoading ? '取得中': '現在地'}}
          </button>
        </div>
      </section>
      <section class="station_list" v-if="stations.length">
        <ul>
          <li v-for="(station, idx) in stations" :key="station.id">
            <a href="" @click.prevent.stop="useStation(station)" :class="{active: idx+1 === activeNo}">{{station.name}}</a>
          </li>
        </ul>
      </section>
    </div>
  </form>
</template>

<script>
import Util from '../../util'
import API from '../api'
import {DBPrefectures} from '../enums'

export default {
  props: ['locationForm'],
  mounted(){
    window.setTimeout(() => { 
      this.$refs.input.click(); 
      this.$refs.input.focus(); 
    }, 600); 
    if (this.locationForm.keyword) {
      this.search(this.locationForm.keyword)
    }
    this.shouldUserLocation = !!navigator.geolocation
  },
  data(){
    return {
      loading: false,
      resdata: null,
      activeNo: 0,
      composing: false,
      shouldUserLocation: false,
      userLocationLoading: false,
    }
  },
  computed: {
    keywordAlt: {
      get(){
        return this.locationForm.keyword || ''
      },
      set(val){
        this.activeNo = 0
        this.locationForm.keyword = val
        if (!val) {
          this.resdata = null
          return
        }
        if (!this.loading) {
          this.search(val)
        }
      }
    },
    keywordKana(){
      return this.keywordAlt.replace(/[\u3041-\u3096]/g, ch =>
        String.fromCharCode(ch.charCodeAt(0) + 0x60)
      );
    },
    stations(){
      if (!this.resdata) {
        return []
      }
      var uniqName = {}
      return (this.resdata.stations || [])
      .filter(row => {
        return row.name.startsWith(this.keywordAlt) || row.reading.startsWith(this.keywordKana)
      })
      .map(row => {
        const id =  Number(row.id);
        if (!id) {
          return null
        }
        const pref_id = Number(row.prefecture_id) || 0
        const name = `${row.name} (${DBPrefectures[pref_id] || ''})`
        if (name in uniqName) {
          return null
        }
        uniqName[name] = id
        return { id, name, pref_id }
      })
      .filter(row => !!row)
      .sort((a,b) => {
        if (a.pref_id === b.pref_id) {
          return 0
        }
        return a.pref_id < b.pref_id ? -1:1
      })
    }
  },
  methods: {
    search(keyword){
      if (this.resdata && keyword.startsWith(this.resdata.keyword)) {
        return
      }
      this.loading = true
      API.searchStations(keyword).then(data => {
        if (data.keyword != this.keywordAlt) {
          if (this.keywordAlt) {
            this.search(this.keywordAlt)
            return
          }
        } else {
          this.resdata = data
        }
        this.loading = false
      }).catch((e) => {
        this.loading = false
        console.log(e)
      })
    },
    reset(){
      this.keywordAlt = ''
      this.$refs.input.focus();
    },
    listDown(){
      if (this.composing) {
        return
      }
      this.activeNo = Math.min(this.stations.length, this.activeNo + 1)
      console.log(this.activeNo, 'listDown')
    },
    listUp(){
      if (this.composing) {
        return
      }
      this.activeNo = Math.max(0, this.activeNo - 1)
      console.log(this.activeNo, 'listUp')
    },
    didEnter(){
      if (this.composing) {
        return
      }
      console.log('didEnter')
      if (!this.activeNo) {
        this.useKeyword()
        return
      }
      const station = this.stations[this.activeNo - 1]
      if (!station) {
        return
      }
      this.useStation(station)
    },
    useKeyword(){
      this.locationForm.stationId = null
      this.locationForm.userLocation = null
      this.locationForm.address = this.locationForm.keyword
      this.close()
    },
    useStation(station){
      this.locationForm.stationId = station.id
      this.locationForm.userLocation = null
      this.locationForm.address = station.name
      this.locationForm.keyword = station.name
      this.close()
    },
    useUserLocation(){
      if (!navigator.geolocation || this.userLocationLoading) {
          return
      }
      this.userLocationLoading = true
      navigator.geolocation.getCurrentPosition((position) => {
        this.userLocationLoading = false
        if (!position) {
          return
        }
        const {latitude, longitude} = position.coords;
        this.locationForm.stationId = null
        this.locationForm.userLocation = {latitude, longitude}
        this.locationForm.address = '現在地'
        this.locationForm.keyword = '現在地'
        this.close()
      }, (error) => {
        this.userLocationLoading = false
        console,log(error, 'error')
        alert(error.message)
      }, {timeout: 5000});
    },
    close(){
      Util.hideModal('#modal_search').then(() => {
        this.$emit('closesuggest')
      })
    }
  }
};
</script>
