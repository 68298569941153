import "core-js/stable";
import "@babel/polyfill";
import domready from "domready";
import "../src/application.scss";
import $ from "jquery";
import Cookies from "js-cookie";
import leaflet from "leaflet";
import Util from '../src/util';
import API from '../src/vue/api';

//Bootstrap
import 'bootstrap'

//Vue
import {vueConstructor, createVueApp} from '../src/vue/vueapps';

//Lottie
import lottie from 'lottie-web';


(window as any).L = leaflet;

import "leaflet.gridlayer.googlemutant";

const CaroucelElementWidth: number = 120;
const encodeQuery = (obj: any) => {
  return Object.keys(obj).reduce(function(a,k){a.push(k+'='+encodeURIComponent(obj[k]));return a},[]).join('&')
}

class Carousel {
  public element: Element;
  public carousel: HTMLElement;
  public carouselCount: number;
  public cursor: number;
  public indicators: HTMLDivElement[];

  constructor(element: Element) {
    this.element = element;
    this.carousel = this.element.querySelector(".pickup-carousel");
    this.carouselCount = Math.ceil(this.carousel.scrollWidth / this.carousel.clientWidth);
    this.cursor = 0;

    const indicator = document.createElement("div");
    indicator.className = "carousel-indicators";
    this.indicators = [];
    for (let i = 0; i < this.carouselCount; i++) {
      const indi = document.createElement("div");
      indi.className = "carousel-indicator";
      if (i === 0) {
        indi.className += " active";
      }
      indicator.appendChild(indi);
      this.indicators.push(indi);
      (n => {
        indi.addEventListener("click", e => {
          e.preventDefault();
          this.cursor = n;
          this.move();
        });
      })(i);
    }
    this.element.appendChild(indicator);

    this.element.querySelector(".carousel-btn-left").addEventListener("click", e => {
      e.preventDefault();
      this.cursor -= 1;
      if (this.cursor < 0) {
        this.cursor = this.carouselCount - 1;
      }
      this.move();
    });
    this.element.querySelector(".carousel-btn-right").addEventListener("click", e => {
      e.preventDefault();
      this.cursor += 1;
      if (this.cursor >= this.carouselCount) {
        this.cursor = 0;
      }
      this.move();
    });
  }

  public move() {
    const targetScrollLeft = this.cursor * this.carousel.clientWidth;
    this.carousel.style.left = `${targetScrollLeft * -1}px`;
    for (let i = 0; i < this.carouselCount; i++) {
      this.indicators[i].className = "carousel-indicator";
      if (i === this.cursor) {
        this.indicators[i].className += " active";
      }
    }
  }
}

var checkStateTimer = null
var checkedSchoolIds = new Set()
var deleteProposaBlockId = null
var deleteProposaSchoolId = null
var selectProposalInfo = null
const forgeSchoolIds = () => {
  checkedSchoolIds = new Set((Cookies.get("checked_school_ids") || "").split(",").filter(Boolean));
}
const saveSchoolIds = () => {
  const arrayIds = Array.from(checkedSchoolIds.values());
  Cookies.set("checked_school_ids", arrayIds.join(","));
};
const syncDisplayCount = () => {
  const $reservelistCountLabel = $('.js_reservelist_count');
  const count = Array.from(checkedSchoolIds.values()).length;
  $reservelistCountLabel.text(count)
  $('.js_reservelist_link').toggleClass('btn_disabled', count == 0)
};
const syncBtnState = ($element, checked) => {
  const schoolInfo = $element.data("info") || {};
  const onlineFlg = !!parseInt(schoolInfo.online_flg)
  const brandOnlineFlg = !!parseInt(schoolInfo.brand_online_flg)
  const brandOnlineFlginfo = !!parseInt(schoolInfo.brand_online_flg_info)
  $element.removeClass('added')
  $element.removeClass('text-2line')
  $element.removeClass('list_in')
  if (checked) {
    $element.addClass('added')
    $element.html('体験予約リスト<br>に追加済')
  } else if(brandOnlineFlginfo == true && brandOnlineFlg == true) {
    $element.addClass('text-2line')
    $element.html('体験予約リストに追加<span>(オンライン可)</span>')
  } else if(brandOnlineFlginfo == false && onlineFlg == true) {
    $element.addClass('text-2line')
    $element.html('体験予約リストに追加<span>(オンライン可)</span>')
  } else {
    $element.addClass('list_in')
    $element.html('体験予約リストに追加')
  }
}

const forgeMap = () => {
  const searchResultMap = document.getElementById("search-result-map");
  if (!searchResultMap) {
    return
  }
  const geo = JSON.parse(searchResultMap.dataset.geo);
  if (!geo) {
    $('.search-result-map').hide();
    return;
  } else{
    $('.search-result-map').show();
  }
  const radius = parseInt(searchResultMap.dataset.radius, 10);

  const point: leaflet.LatLngExpression = [geo.lat as number, geo.lng as number];
  let zoom = 16;
  if (radius >= 700) {
    zoom = 15;
  }
  if (radius >= 2000) {
    zoom = 14;
  }
  if (radius >= 3000) {
    zoom = 13;
  }
  if (radius >= 5000) {
    zoom = 12;
  }
  if (window.outerWidth <= 800) {
    zoom -= 1;
  }


  const map = leaflet.map(searchResultMap, { attributionControl: false, scrollWheelZoom: false, dragging: true, tap: false });
  map.createPane('uncontract').style.zIndex = '610';
  map.createPane('contract').style.zIndex = '620';

  (leaflet.gridLayer as any)
    .googleMutant({
      type: "roadmap", // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
    })
    .addTo(map);

  leaflet
    .circle(point, {
      radius,
      color: "#D13443",
      fill: true,
      fillColor: "#FFBFC5",
    })
    .addTo(map);

  map.setView(point, zoom);

  const schools = Array.from(document.getElementsByClassName("search-result-school"));

  schools.reverse().forEach((school: HTMLElement, idx: number) => {
    const mapIndex = school.dataset.mapIndex || 0
    const info = JSON.parse(school.dataset.info);
    const hasContract = Number(info.brand_contract) == 1;
    const numberIcon = leaflet.divIcon({
      className: hasContract ? "number-active-icon" : "number-deactive-icon",
      iconSize: [35, 36],
      iconAnchor: [10, 44],
      popupAnchor: [15, -40],
      html: `${mapIndex}`,
    });

    if (!info.location[0] || !info.location[1]) {
      return
    }

    leaflet
      .marker(info.location as leaflet.LatLngExpression, {
        title: info.name,
        icon: numberIcon,
        pane: hasContract ? 'contract' : 'uncontract',
      })
      // .bindPopup(info.name)
      .on("click", () => {
        // schools.forEach(s => s.classList.remove("highlight"));
        // school.classList.add("highlight");
        $('body,html').animate({
          scrollTop: $(school).offset().top - 80 //フローティングヘッダ分-
        }, 500);
      })
      .addTo(map);
  });
}

domready(() => {
  console.log('application.ts domready')

  if (document.getElementById("pita_video")) {
    lottie.loadAnimation({
      container: document.getElementById("pita_video"),　//HTMLのID
      renderer: "svg",
      loop: true, //ループ
      autoplay: true,　//自動再生
      path: "/jyukupita_re_20201106.json"　//jsonファイルの場所
  });
  }

  if ($('.js_reservelist_count').length) {
    forgeSchoolIds()
    syncDisplayCount();
  }

  // 塾をリスト追加・除外
  if (document.querySelector('.js_reservelist_btn')) {
    forgeSchoolIds()
    syncDisplayCount();

    $(".js_reservelist_btn").each((idx, element) => {
      const $element = $(element);
      if (!$element.data("school-id")) {
        return
      }
      const schoolId = `${$element.data("school-id") || 0}`;
      const schoolInfo = $element.data("info") || {};
      syncBtnState($element, checkedSchoolIds.has(schoolId))

      $element.on("click", e => {
        e.preventDefault();
        if ($element.hasClass("disabled")) {
          return false;
        }

        if (checkedSchoolIds.has(schoolId)) {
          checkedSchoolIds.delete(schoolId);
          syncBtnState($element, false)
        } else {
          checkedSchoolIds.add(schoolId);
          syncBtnState($element, true)
          $('.list_in_text').addClass('active').stop().removeAttr('style').fadeOut( 4000, () => {
            $('.list_in_text').removeClass('active').removeAttr('style');
          } );
        }
        saveSchoolIds();
        syncDisplayCount();
      });
    });

    //別タブでのチェック変動を画面に反映
    checkStateTimer = setInterval(() => {
      forgeSchoolIds()
      syncDisplayCount();
      $(".js_reservelist_btn").each((idx, element) => {
        const $element = $(element);
        const schoolId = `${$element.data("school-id")}`;
        syncBtnState($element, checkedSchoolIds.has(schoolId))
      })
    }, 2000);
  }


  //予約リストへのリンク
  if ($('.js_reservelist_link').length) {
    $('.js_reservelist_link').on('click', (event) => {
      event.preventDefault();
      forgeSchoolIds()
      if (Array.from(checkedSchoolIds.values()).length) {
        window.location.href ="/reserve_list"
      }
    })
    const elements = document.getElementsByClassName('search-result-list')
    if (elements.length > 0) {
      const winHeight = $(window).height()
      const elem = elements[0]
      const syncFloatHeader = () => {
        const windowBottom = window.pageYOffset + $(window).height()
        const targetTop    = window.pageYOffset + elem.getBoundingClientRect().top;
        if ( windowBottom > targetTop ) {
          if ($('div').hasClass('pc_header_long')) {
            // 塾詳細用
            $('.pc_header_long').addClass( 'm_fixed' );
            $('#breadcrumb').addClass( 'm_fixed' );
          } else {
            $( '#pc_header' ).addClass( 'm_fixed' );
          }
          $( '#sp_headerE' ).addClass( 'm_fixed' );
        } else {
          if ($('div').hasClass('pc_header_long')) {
            // 塾詳細用
            // console.log('application.ts pc_header_long yes')
            $('.pc_header_long').addClass( 'm_fixed' );
            $('#breadcrumb').removeClass( 'm_fixed' );
          } else {
          $( '#pc_header' ).removeClass( 'm_fixed' );
          }
          $( '#sp_headerE' ).removeClass( 'm_fixed' );
        }
      }
      $(window).on('scroll', function() {
        syncFloatHeader()
      });
      syncFloatHeader()
    }
  }

  const pickupsElements = document.querySelectorAll(".pickups");
  const pickupCarousels: Carousel[] = [];
  pickupsElements.forEach(pickups => {
    pickupCarousels.push(new Carousel(pickups));
  });

  // マイページ　確定の重複クリック防止
  $('.mypage_select').click(function(){
      console.log('click mypage_select')
      $(this).append('<div id="loading_box"></div>');
      $('.mypage_select').css('pointer-events', 'none');
      $('.mypage_select').css('cursor', 'none');
      $('.btn').css('pointer-events', 'none');
      $('.btn').css('cursor', 'none');
  });


  // マイページ 内部リンク
  $('.innner_link a[href^="#"]').click(function(){
    // console.log('click innner link2')
      var adjust = -60;
      var speed = 500;
      var href= $(this).attr("href");
      var target = $(href == "#" || href == "" ? 'html' : href);
      var position = target.offset().top + adjust;
      $("html, body").animate({scrollTop:position}, speed, "swing");
      return false;
  });

  //マイページ日程削除
  if (document.getElementById("modal_delete_proposal_confirm")) {
    $('.js_show_delete_proposal_confirm').on('click', e => {
      e.preventDefault()
      deleteProposaBlockId = $(e.currentTarget).data('block_id');
      $('#modal_delete_proposal_confirm').modal()
    })
    $('#js_delete_proposal_execute').on('click', e => {
      const _deleteBlockId = deleteProposaBlockId
      console.log("_deleteBlockId:" + _deleteBlockId);
      if (!_deleteBlockId) { return }
      deleteProposaBlockId = null
      $('#modal_delete_proposal_confirm').modal('hide');
      $('#js_modal_loading').modal();
      // location.href = `/mypage/top/delete/${_deleteBlockId}`
      var ajax_url = '/mypage/top/delete/' + _deleteBlockId
      console.log("ajax_url:" + ajax_url);
      $.ajax({
         url:ajax_url,
         type:'get',
         cache: false,
         success:function(mydata){
            location.reload();
         },
         error: function(XMLHttpRequest, status, errorThrown){
            //失敗時の処理
            // console.log("status:" + status);
            // console.log("message:" + errorThrown);
            Util.hideModal('#js_modal_loading')
            // console.log("error");
            alert('エラーが発生しました')
         },
         complete:function(mydata){
            // console.log("complete");
            location.reload();
         }
      });
    })
  }


  //マイページ塾削除
  if (document.getElementById("modal_delete_proposal_school_confirm")) {
    $('.js_show_delete_proposal_school_confirm').on('click', e => {
      e.preventDefault()
      deleteProposaSchoolId = $(e.currentTarget).data('school_id');
      // console.log("deleteProposaSchoolId:" + deleteProposaSchoolId);
      $('#modal_delete_proposal_school_confirm').modal()
    })
    $('#js_delete_proposal_execute').on('click', e => {
      const _deleteSchoolId = deleteProposaSchoolId
      // console.log("_deleteSchoolId:" + _deleteSchoolId);
      if (!_deleteSchoolId) { return }
      deleteProposaSchoolId = null
      $('#modal_delete_proposal_school_confirm').modal('hide');
      $('#js_modal_loading').modal();
      API.deleteSchool(_deleteSchoolId).then(() => {
        location.reload();
      }).catch(() => {
        Util.hideModal('#js_modal_loading')
        alert('エラーが発生しました')
      });
    })
  }


  //マイページ日程決定
  if (document.getElementById("js_modal_select_proposal_confirm")) {
    $('.js_modal_select_proposal_confirm').on('click', e => {
      e.preventDefault()
      // selectProposalInfo = $(e.currentTarget).data('select_info');
      selectProposalInfo = $('.js_modal_select_proposal_confirm2').text();
      selectProposalInfo = JSON.parse(selectProposalInfo);
      // console.log(selectProposalInfo);
      $('#js_modal_select_proposal_confirm').find('.js_place_date').text(selectProposalInfo.block_label || '').end().modal()
    })
    $('#js_select_proposal_execute').on('click', e => {
      const _selectInfo = selectProposalInfo
      if (!_selectInfo) { return }
      selectProposalInfo = null
      Util.hideModal('#js_modal_select_proposal_confirm')
      .then(() => {
        return Util.showModalSending()
      }).then(() => {
        return API.selectReserve(_selectInfo.school_id, _selectInfo.block_school_id);
      }).then(() => {
        return Util.hideModalSending()
      }).then(() => {
        location.href = `/mypage/select/complete/${_selectInfo.school_id}/${_selectInfo.user_id}`
        // $('#js_modal_select_proposal_complete').modal()
      }).catch(e => {
        console.log(e)
        Util.hideModal('#js_modal_loading')
        alert('エラーが発生しました')
      })
    })
  }

  // 塾詳細 「感染症対策・授業について」モーダル表示
  if (document.getElementById("infection")) {
    $('.js-open-modal').on('click', e => {
      e.preventDefault()
      // console.log("infection");
      $('#infection').modal()
    });
  }

  // 検索結果
  forgeMap();


  // スマホメニュー
  const spMenuOpen = document.getElementById("header-menu");
  const spMenuClose = document.getElementById("sp_menu_close");
  const spMenu = document.getElementById("sp_menu");
  if (spMenuOpen && spMenu && spMenuClose) {
    spMenuOpen.addEventListener("click", e => {
      e.preventDefault();

      spMenu.classList.add("opened");
      $('body').addClass("sp_menu_open");
    });

    spMenuClose.addEventListener("click", e => {
      e.preventDefault();

      spMenu.classList.remove("opened");
      $('body').removeClass("sp_menu_open");
    });

    spMenu.addEventListener("click", e => {
      if (e.target === spMenu) {
        e.preventDefault();
        spMenu.classList.remove("opened");
        $('body').removeClass("sp_menu_open");
      }
    });
  }

  // ドロップダウンリスト
  const dropdownLists = Array.from(document.querySelectorAll(".list .with-dropdown"));
  dropdownLists.forEach(dropdownList => {
    if (dropdownList.classList.contains("do-not-add-event-by-application-js")) {
      return true;
    }
    const listItem = dropdownList.querySelector(".list-item")
    listItem.addEventListener("click", e => {
      dropdownList.classList.toggle("opened");
    });
    listItem.classList.toggle("clickable")
  });

  // 電車絞り込み
  const railwayFilter = document.getElementById("railway-filter") as HTMLInputElement;
  if (railwayFilter) {
    railwayFilter.addEventListener("input", e => {
      const name = railwayFilter.value;
      const railways = Array.from(document.querySelectorAll(".railway")) as HTMLDivElement[];
      railways.forEach(railway => {
        if (railway.getAttribute("data-name").includes(name) || name === "") {
          railway.style.display = "";
        } else {
          railway.style.display = "none";
        }
      });
    });
    railwayFilter.addEventListener("submit", e => {
      e.preventDefault();
    });
  }

  // トップへ
  const returnToTop = document.getElementById("return-to-top");
  if (returnToTop) {
    returnToTop.addEventListener("click", e => {
      e.preventDefault();

      window.scrollTo(0, 0);
    });
  }

  // 教室一覧ソート
  const schoolPager = document.querySelectorAll("form.pager");
  schoolPager.forEach(pager => {
    pager.querySelectorAll("select").forEach(e => {
      e.addEventListener("change", () => {
        (pager as HTMLFormElement).submit();
      });
    });
  });

  // 戻るボタン
  const buttonOfBacks = document.querySelectorAll(".button-of-back");
  buttonOfBacks.forEach(button => {
    button.addEventListener('click', (e) => {
      e.preventDefault();
      history.back();
    });
  });

  window.addEventListener('pageshow', function(event) {
    console.log('pageshow')
    $('#js_modal_loading').modal('hide')
    if (event.persisted) {
      console.log('Page was loaded from cache.');
    }
  });

  //ご利用ガイド
  if (document.getElementById("js_user_guide")) {
    // 最初は全部隠す
    $(".panel dd").hide();
    $(".panel dt").click(function(){
        $(".panel dd").slideUp(0);
        $(".panel dt").removeClass("active");
        if(!$(this).hasClass("active")){
            $(this).addClass("active").next().slideDown(0 , function(){
                var scrollPoint = $(this).offset().top - $(".panel dt").height() - 87; //スクロール位置調整
                $('body,html').animate({ scrollTop : scrollPoint});
                return false;
            });
        }
    });
    //　閉じるボタンのクリック処理
    $('.panel dd .close').click(function(){
        $(this).parent('.panel dd').slideUp(0);
        $(".panel dt").removeClass("active");
    });
  }


  var topBtn = $('#page-top');
  topBtn.hide();
  //スクロールが500に達したらボタン表示
  $(window).scroll(function () {
      if ($(this).scrollTop() > 500) {
          topBtn.fadeIn();
      } else {
          topBtn.fadeOut();
      }
  });
  //スルスルっとスクロールでトップへもどる
  topBtn.click(function () {
      $('body,html').animate({
          scrollTop: 0
      }, 500);
      return false;
  });

  const vueInit = vueConstructor()
  if (vueInit) {
    (window as any).vueapp = createVueApp(vueInit)
  } else {
    $('[data-toggle="popover"]').popover();
    $('[data-toggle="tooltip"]').tooltip();
  }
});
