var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "efo-form customer_regist_confirm",
      attrs: {
        id: "efoForm",
        action: "",
        method: "",
        novalidate: "",
        "data-validate": "",
        "data-nextrequired-flag": "1"
      },
      on: { submit: _vm.willUpdate, change: _vm.updateStatus }
    },
    [
      _c("h4", { staticClass: "h4-ttl ml-0" }, [_vm._v("認証コードを入力")]),
      _vm._v(" "),
      _c("p", { staticClass: "ttl-subtext" }, [
        _vm._v(
          "SMS（ショートメッセージ）で受け取った認証コード（４桁の番号）を入力してください。"
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "customer_regist_form" }, [
        _c(
          "label",
          {
            staticClass: "mb-0 tel required",
            class: { ok: _vm.inputs_status.smscode },
            attrs: { for: "sms-code" }
          },
          [_vm._v("認証コード")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group pl-0 pr-0 mb-0" }, [
          _c("div", { staticClass: "input-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.smscode,
                  expression: "smscode"
                }
              ],
              staticClass: "form-control size_m alert",
              class: { "alert-danger": !_vm.inputs_status.smscode },
              attrs: {
                type: "tel",
                "data-type": "smscode",
                name: "smscode",
                maxlength: "4",
                placeholder: "例）0000",
                id: "sms-code",
                required: ""
              },
              domProps: { value: _vm.smscode },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.smscode = $event.target.value
                }
              }
            })
          ]),
          _vm._v(" "),
          _vm.validation_errors.smscode
            ? _c("p", { staticClass: "error-text" }, [
                _vm._v(_vm._s(_vm.validation_errors.smscode))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm._m(0)
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "sms-confirm" }, [
        _c("p", { staticClass: "text-info sp" }, [
          _c(
            "button",
            {
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.showModal("#modal2")
                }
              }
            },
            [
              _c("i", {
                staticClass: "fas fa-exclamation-triangle fa-fw mr-1"
              }),
              _vm._v("SMSが届かない場合")
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "check_area" }, [
          _c("div", { staticClass: "pl-0" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.smsCheck,
                  expression: "smsCheck"
                }
              ],
              staticClass: "sms-check all",
              attrs: {
                type: "checkbox",
                id: "sms-check",
                name: "sms-check",
                required: ""
              },
              domProps: {
                checked: Array.isArray(_vm.smsCheck)
                  ? _vm._i(_vm.smsCheck, null) > -1
                  : _vm.smsCheck
              },
              on: {
                change: function($event) {
                  var $$a = _vm.smsCheck,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.smsCheck = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.smsCheck = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.smsCheck = $$c
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("label", {
              staticClass: "sms-check",
              attrs: { for: "sms-check" }
            })
          ]),
          _vm._v(" "),
          _vm._m(1)
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "w-100 text-center mt-3 mb-10" }, [
          _c(
            "button",
            {
              staticClass: "btn col-md-6 col-11 suggest2 btn_confirm",
              attrs: { id: "submit-entry2", disabled: !_vm.submit_active }
            },
            [
              _c("span", { staticClass: "small" }, [
                _vm._v("ここまでの情報を登録して")
              ]),
              _c("br"),
              _vm._v("体験希望日の日程選択へ")
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "sms-confirm ml-auto mr-auto" },
        [
          _c("h4", { staticClass: "h4-ttl ml-0" }, [_vm._v("入力情報の確認")]),
          _vm._v(" "),
          _vm._t("default"),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "check_area" }, [
            _c("div", { staticClass: "pl-0" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.smsCheck,
                    expression: "smsCheck"
                  }
                ],
                staticClass: "sms-check all",
                attrs: {
                  type: "checkbox",
                  id: "sms-check2",
                  name: "sms-check",
                  required: ""
                },
                domProps: {
                  checked: Array.isArray(_vm.smsCheck)
                    ? _vm._i(_vm.smsCheck, null) > -1
                    : _vm.smsCheck
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.smsCheck,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.smsCheck = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.smsCheck = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.smsCheck = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c("label", {
                staticClass: "sms-check",
                attrs: { for: "sms-check" }
              })
            ]),
            _vm._v(" "),
            _vm._m(3)
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w-100 text-center mt-3 mb-10" }, [
            _c(
              "button",
              {
                staticClass: "btn col-md-6 col-11 suggest2 btn_confirm",
                attrs: { id: "submit-entry2", disabled: !_vm.submit_active }
              },
              [
                _c("span", { staticClass: "small" }, [
                  _vm._v("ここまでの情報を登録して")
                ]),
                _c("br"),
                _vm._v("体験希望日の日程選択へ")
              ]
            )
          ])
        ],
        2
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-info pc" }, [
      _c(
        "button",
        {
          attrs: {
            type: "button",
            "data-toggle": "modal",
            "data-target": "#modal2"
          }
        },
        [
          _c("img", {
            attrs: { src: "/assets/icon_exclamation.svg", alt: "！" }
          }),
          _vm._v("SMSが届かない場合")
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pl-0" }, [
      _c("p", { staticClass: "mb-0 palt" }, [
        _vm._v("「"),
        _c("a", { attrs: { href: "/terms", target: "_blank" } }, [
          _vm._v("利用規約")
        ]),
        _vm._v("」「"),
        _c(
          "a",
          {
            attrs: { href: "https://www.jiem.co.jp/privacy/", target: "_blank" }
          },
          [_vm._v("プライバシーポリシー")]
        ),
        _vm._v("」に同意する。")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-right mb-5" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-sm ml-auto back_regist",
          attrs: {
            type: "button",
            onclick: "location.href = '/customer/regist';"
          }
        },
        [_vm._v("情報を修正する")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pl-0" }, [
      _c("p", { staticClass: "mb-0 palt" }, [
        _vm._v("「"),
        _c("a", { attrs: { href: "/terms", target: "_blank" } }, [
          _vm._v("利用規約")
        ]),
        _vm._v("」「"),
        _c(
          "a",
          {
            attrs: { href: "https://www.jiem.co.jp/privacy/", target: "_blank" }
          },
          [_vm._v("プライバシーポリシー")]
        ),
        _vm._v("」に同意する。")
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6a2a5da1", { render: render, staticRenderFns: staticRenderFns })
  }
}