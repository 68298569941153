<template>
  <form id="efoForm" class="efo-form customer_regist" action="" method="" novalidate data-validate @submit="willUpdate" @change="updateStatus" data-nextrequired-flag="1">
  <h4 class="h4-ttl ml-0">お客様情報の入力</h4>
  <slot />
  <div class="customer_regist_form">
    <label for="zip_code" class="mb-0 zip_code required" :class="{ok: inputs_status.zip_code}">郵便番号(半角/ハイフン不要)</label>
      <div class="form-group">
        <div class="input-group">
          <input type="tel" id="zip_code" data-type="zip" name="zip_code" v-model="form.zip_code" class="form-control size_m alert" :class="{'alert-danger': !inputs_status.zip_code}" role="alert" placeholder="例）1506013" maxlength="7" required @keyup="fillAddress">
        </div>
        <p class="error-text p-0" v-if="validation_errors.zip_code" v-cloak>{{validation_errors.zip_code}}</p>
        <ul class="error-text" v-if="address_candidate" v-cloak>
          <li v-for="(add, index) in address_candidate" @click="selectAddress(index)" :key="index">{{add.address}}</li>
        </ul>
      </div>

      <label for="address_01" class="mb-0 address_01 required" :class="{ok: inputs_status.address_01}">都道府県</label>
    <div class="form-group">
      <select name="address_01" id="address_01" v-model="form.address_01" class="form-control size_l alert" :class="{'alert-danger': !inputs_status.address_01}"  role="alert" required>
        <option value="" selected disabled>郵便番号から自動入力されます</option>
        <option v-for="(label, value) in prefs" :key="value"  :value="value">{{label}}</option>
      </select>
      <p class="error-text" v-if="validation_errors.address_01" v-cloak>{{validation_errors.address_01}}</p>
    </div>
      <label for="address_02" class="mb-0 address_02 required" :class="{ok: inputs_status.address_02}">市区町村</label>
    <div class="form-group">
      <input type="text" id="address_02" name="address_02" v-model="form.address_02" class="form-control size_l alert" :class="{'alert-danger': !inputs_status.address_02}" role="alert" placeholder="郵便番号から自動入力されます" required>
      <p class="error-text" v-if="validation_errors.address_02" v-cloak>{{validation_errors.address_02}}</p>
    </div>
      <label for="address_03" class="mb-0 address_03 required" :class="{ok: inputs_status.address_03}">番地・建物名</label>
    <div class="form-group">
      <input type="text" id="address_03" name="address_03" v-model="form.address_03" class="form-control size_l alert" :class="{'alert-danger': !inputs_status.address_03}"  role="alert" placeholder="例）1-2-3 ○○マンション101" required>
      <p class="error-text" v-if="validation_errors.address_03" v-cloak>{{validation_errors.address_03}}</p>
    </div>
    <label for="name_01" class="mb-0 name_01 required" :class="{ok: inputs_status.name_01 && inputs_status.name_02}">お名前（漢字）</label>
      <div class="form-group col_name">
        <div class="input-group">
          <span>姓</span>
          <input type="text" id="name_01" name="name_01" v-model="form.name_01" class="form-control size_s alert" :class="{'alert-danger': !inputs_status.name_01}"  role="alert" placeholder="例）塾田" required aria-label="お名前(姓)">
        </div>
        <div class="input-group">
          <span>名</span>
          <input type="text" id="name_02" name="name_02" v-model="form.name_02" class="form-control size_s alert" :class="{'alert-danger': !inputs_status.name_02}" role="alert" placeholder="例）太郎" required aria-label="お名前(名)">
        </div>
        <p class="error-text mb-0">
            <span class="pl-1" v-if="validation_errors.name_01" v-cloak>{{validation_errors.name_01}}<br></span>
            <span class="pl-1" v-if="validation_errors.name_02" v-cloak>{{validation_errors.name_02}}</span>
        </p>
      </div>
    <label for="name_03" class="mb-0 name_03 required" :class="{ok: inputs_status.name_03 && inputs_status.name_04}">お名前（カナ）</label>
      <div class="form-group col_name">
        <div class="input-group">
          <span>セイ</span>
          <input type="text" id="name_03" name="name_03" v-model="form.name_03" class="form-control size_s alert" :class="{'alert-danger': !inputs_status.name_03}" role="alert" placeholder="例）ジュクダ" data-type="katakana" required aria-label="フリガナ(姓)">
        </div>
        <div class="input-group">
          <span>メイ</span>
          <input type="text" id="name_04" name="name_04" v-model="form.name_04" class="form-control size_s alert" :class="{'alert-danger': !inputs_status.name_04}" role="alert" placeholder="例）タロウ" data-type="katakana" required aria-label="フリガナ(名)">
        </div>
          <p class="error-text mb-0">
            <span class="pl-1" v-if="validation_errors.name_03" v-cloak>{{validation_errors.name_03}}<br></span>
            <span class="pl-1" v-if="validation_errors.name_04" v-cloak>{{validation_errors.name_04}}</span>
          </p>
      </div>

      <label for="maile" class="mb-0 maile required" :class="{ok: inputs_status.mail}">メールアドレス</label>
    <div class="form-group">
      <input type="email" id="maile" name="mail" data-type="email" v-model="form.email"  class="form-control size_l alert" :class="{'alert-danger': !inputs_status.mail}" role="alert" placeholder="例）jiem@jyukupita.jp" required>
      <p class="error-text" v-if="validation_errors.mail" v-cloak>{{validation_errors.mail}}</p>
    </div>
      <label for="tel" class="mb-0 tel required" :class="{ok: inputs_status.tel}">携帯電話番号</label>
    <div class="form-group">
      <input type="tel" id="tel" name="tel" data-type="tel"  v-model="form.tel"  class="form-control size_l alert" :class="{'alert-danger': !inputs_status.tel}" role="alert" maxlength="11" placeholder="例）08012345678" required>
      <p class="error-text" v-if="validation_errors.tel" v-cloak>{{validation_errors.tel}}</p>
    </div>
    <div class="attention">
      <p class="alert alert-primary" role="alert"><i class="fas fa-exclamation-triangle fa-fw"></i>ご本人様確認のため、ショートメッセージ（SMS）で認証コード（SMS認証）をお送りします。</p>
    </div>
  </div>
    <div class="required-item">
      <dl class="mb-0">
        <dd class="text-center" v-if="remain_required > 0" v-cloak><strong>必須項目</strong><br>残り<strong class="text-danger is-text-size-xlarge">{{remain_required}}</strong>個</dd>
        <dd class="text-left" v-else v-cloak>入力お疲れ様でした。<br><strong>[確認画面へ]</strong><br>ボタンを押してください。</dd>
      </dl>
    </div>
    
    <div class="w-100 text-center mt-40 mb-50"><button id="to-confirm1" type="submit" class="btn btn_notice suggest" :disabled="!submit_active">確認画面へ</button></div>
  </form>
</template>

<script>

import Mixin from '../mixin'
import axios from 'axios'
import API from '../api'
import Types from '../types'
import Util from '../../util'
import { InvalidError, ForbiddenError } from '../enums'
import { JSPrefectures, DBSchoolGrades } from '../enums'

export default {
  mixins: [Mixin.EfoForm],
  data(){
    return {
      form: Types.SignUpForm(),
      address_candidate: null
    }
  },
  computed:{
    prefs: () => JSPrefectures,
    grades: () => DBSchoolGrades
  },
  mounted(){
    console.log('registerform mounted')
    if (localStorage.signUpForm) {
      this.form.fromPersist(JSON.parse(localStorage.signUpForm))
    }
    setTimeout(this.updateStatus, 50);
  },
  methods: {
    cacheFormBeforeUnload () {
      localStorage.setItem('signUpForm', JSON.stringify(this.form.toPersist()))
    },
    fillAddress () {
      /**
       * 郵便番号から住所を検索してフィルイン
       */
      let zip_code = this.inputs.find((input) => {return input.name === 'zip_code';});
      if(!zip_code.validate()) {
        return false;
      }

      let endpoint = 'https://madefor.github.io/postal-code-api/api/v1/' + zip_code.getValue.slice(0,3) + '/' + zip_code.getValue.slice(-4) + '.json';

      this.address_candidate = null;

      axios.get(endpoint)
      .then((response) => {
        let address = response.data.data;

        if(address && address.length === 1) {
          //this.prefecture = address[0].ja.prefecture;
          this.form.address_01 = parseInt(address[0].prefcode) || '';
          this.form.address_02 = address[0].ja.address1 + address[0].ja.address2;
          setTimeout(this.updateStatus, 60);

        } else if (address.length > 1) {
          this.address_candidate = [];
          address.forEach((ad) => {
            this.address_candidate.push({
              prefecture: ad.ja.prefecture,
              prefcode: parseInt(ad.prefcode) || '',
              city: ad.ja.address1 + ad.ja.address2,
              address: ad.ja.prefecture + ad.ja.address1 + ad.ja.address2
            });
          });
        }
      })
      .catch((error) => {
        console.log('no postal data', error);
        setTimeout(this.updateStatus, 50);
      });
    },
    selectAddress (index) {
      /**
       * 住所一覧から選択
       */
      //this.prefecture = this.address_candidate[index].prefecture;
      this.form.address_01 = this.address_candidate[index].prefcode;

      this.form.address_02 = this.address_candidate[index].city;

      this.address_candidate = null;
      setTimeout(this.updateStatus, 60);
    },
    willUpdate(event){
      event.preventDefault();
      if(this.locked) {
        return false;
      }

      if(!this.validate()){
        event.preventDefault();

        // 不備のある項目の先頭にフォーカスを当てる
        let item = this.inputs.find((input)=> {return input.error;});
        item.element.focus();
        //console.log('NG', this.validation_errors);
        return false;
      }
      this.locked = true;
      const params = this.form.params()
      Util.showModalSending().then(() => {
        return API.postSignUp(params)
      })
      .then(Util.hideModalSending)
      .then(() => {
      	this.locked = false;
        this.cacheFormBeforeUnload()
        window.location.href = '/customer/auth'
      }).catch(e => {
      	this.locked = false;
				if (e instanceof InvalidError) {
					console.log(e.code)
					switch (e.code) {
						case 'DUPLICATE':
              Util.hideModalSending().then(() => {
							  Util.showModal('#modal4')
              })
							return
						default:
							break;
					}
				}
        console.log(e)
        alert('エラーが発生しました')
      })
      return false
    }
  }
}
</script>

<style>

</style>