<template>
    <form id="customer_info" class="login_sms" data-validate novalidate @submit="willUpdate" @change="updateStatus" :data-remains="remain_required" data-nextrequired-flag="1">
        <h4 class="h4-ttl ml-0">ご本人確認</h4>
        <p class="mb-4">ご本人確認のため、ご登録済みの携帯電話番号にSMS（ショートメッセージ）で認証コード（SMS認証）をお送りします。</p>
        <div class="form-group">
            <label for="tel" class="mb-0 tel required" :class="{ok: inputs_status.tel}">携帯電話番号</label>
            <input type="tel" id="tel" name="tel" v-model="tel" data-type="tel" class="form-control alert" :class="{'alert-danger': !inputs_status.tel}" role="alert" placeholder="例)08012345678" maxlength="11" required>
            <p class="error-text" v-if="validation_errors.tel" v-cloak>{{validation_errors.tel}}</p>
        </div>
        <div class="w-100 text-center mt-40 mb-10"><button id="sms-send" class="btn suggest2 btn_code" :disabled="!submit_active">認証コードを送信する</button></div>
    </form>
</template>

<script>

import Mixin from '../mixin'
import { InvalidError } from '../enums'
import API from '../api'
import Util from '../../util'
import { JSPrefectures, DBSchoolGrades } from '../enums'

export default {
  mixins: [Mixin.EfoForm],
  data(){
    return {
      tel: '',
    }
  },
  methods: {
    willUpdate(event){
      event.preventDefault();
      if(this.locked) {
        return false;
      }

      if(!this.validate()){
        event.preventDefault();

        // 不備のある項目の先頭にフォーカスを当てる
        let item = this.inputs.find((input)=> {return input.error;});
        item.element.focus();
        //console.log('NG', this.validation_errors);
        return false;
      }
      this.locked = true;
      Util.showModalSending().then(() => {
        return API.postSignIn({ phone_number: this.tel })
      })
      .then(Util.hideModalSending)
      .then(() => {
        window.location.href = '/login/auth'
      }).catch(e => {
      	this.locked = false;
				if (e instanceof InvalidError) {
					console.log(e.code)
					switch (e.code) {
						case 'NOTFOUND':
              Util.hideModalSending().then(() => {
                Util.showModal('#modal4')
              })
							return
						default:
							break;
					}
				}
        console.log(e)
        alert('エラーが発生しました')
      })
      return false
    }
  }
}
</script>

<style>

</style>