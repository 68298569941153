var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "efo-form customer_regist",
      attrs: {
        id: "efoForm",
        action: "",
        method: "",
        novalidate: "",
        "data-validate": "",
        "data-nextrequired-flag": "1"
      },
      on: { submit: _vm.willUpdate, change: _vm.updateStatus }
    },
    [
      _c("h4", { staticClass: "h4-ttl ml-0" }, [_vm._v("お客様情報の入力")]),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c("div", { staticClass: "customer_regist_form" }, [
        _c(
          "label",
          {
            staticClass: "mb-0 zip_code required",
            class: { ok: _vm.inputs_status.zip_code },
            attrs: { for: "zip_code" }
          },
          [_vm._v("郵便番号(半角/ハイフン不要)")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "input-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.zip_code,
                  expression: "form.zip_code"
                }
              ],
              staticClass: "form-control size_m alert",
              class: { "alert-danger": !_vm.inputs_status.zip_code },
              attrs: {
                type: "tel",
                id: "zip_code",
                "data-type": "zip",
                name: "zip_code",
                role: "alert",
                placeholder: "例）1506013",
                maxlength: "7",
                required: ""
              },
              domProps: { value: _vm.form.zip_code },
              on: {
                keyup: _vm.fillAddress,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "zip_code", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _vm.validation_errors.zip_code
            ? _c("p", { staticClass: "error-text p-0" }, [
                _vm._v(_vm._s(_vm.validation_errors.zip_code))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.address_candidate
            ? _c(
                "ul",
                { staticClass: "error-text" },
                _vm._l(_vm.address_candidate, function(add, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      on: {
                        click: function($event) {
                          return _vm.selectAddress(index)
                        }
                      }
                    },
                    [_vm._v(_vm._s(add.address))]
                  )
                }),
                0
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "mb-0 address_01 required",
            class: { ok: _vm.inputs_status.address_01 },
            attrs: { for: "address_01" }
          },
          [_vm._v("都道府県")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.address_01,
                  expression: "form.address_01"
                }
              ],
              staticClass: "form-control size_l alert",
              class: { "alert-danger": !_vm.inputs_status.address_01 },
              attrs: {
                name: "address_01",
                id: "address_01",
                role: "alert",
                required: ""
              },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.form,
                    "address_01",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _c(
                "option",
                { attrs: { value: "", selected: "", disabled: "" } },
                [_vm._v("郵便番号から自動入力されます")]
              ),
              _vm._v(" "),
              _vm._l(_vm.prefs, function(label, value) {
                return _c(
                  "option",
                  { key: value, domProps: { value: value } },
                  [_vm._v(_vm._s(label))]
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _vm.validation_errors.address_01
            ? _c("p", { staticClass: "error-text" }, [
                _vm._v(_vm._s(_vm.validation_errors.address_01))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "mb-0 address_02 required",
            class: { ok: _vm.inputs_status.address_02 },
            attrs: { for: "address_02" }
          },
          [_vm._v("市区町村")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.address_02,
                expression: "form.address_02"
              }
            ],
            staticClass: "form-control size_l alert",
            class: { "alert-danger": !_vm.inputs_status.address_02 },
            attrs: {
              type: "text",
              id: "address_02",
              name: "address_02",
              role: "alert",
              placeholder: "郵便番号から自動入力されます",
              required: ""
            },
            domProps: { value: _vm.form.address_02 },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "address_02", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.validation_errors.address_02
            ? _c("p", { staticClass: "error-text" }, [
                _vm._v(_vm._s(_vm.validation_errors.address_02))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "mb-0 address_03 required",
            class: { ok: _vm.inputs_status.address_03 },
            attrs: { for: "address_03" }
          },
          [_vm._v("番地・建物名")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.address_03,
                expression: "form.address_03"
              }
            ],
            staticClass: "form-control size_l alert",
            class: { "alert-danger": !_vm.inputs_status.address_03 },
            attrs: {
              type: "text",
              id: "address_03",
              name: "address_03",
              role: "alert",
              placeholder: "例）1-2-3 ○○マンション101",
              required: ""
            },
            domProps: { value: _vm.form.address_03 },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "address_03", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.validation_errors.address_03
            ? _c("p", { staticClass: "error-text" }, [
                _vm._v(_vm._s(_vm.validation_errors.address_03))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "mb-0 name_01 required",
            class: {
              ok: _vm.inputs_status.name_01 && _vm.inputs_status.name_02
            },
            attrs: { for: "name_01" }
          },
          [_vm._v("お名前（漢字）")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group col_name" }, [
          _c("div", { staticClass: "input-group" }, [
            _c("span", [_vm._v("姓")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.name_01,
                  expression: "form.name_01"
                }
              ],
              staticClass: "form-control size_s alert",
              class: { "alert-danger": !_vm.inputs_status.name_01 },
              attrs: {
                type: "text",
                id: "name_01",
                name: "name_01",
                role: "alert",
                placeholder: "例）塾田",
                required: "",
                "aria-label": "お名前(姓)"
              },
              domProps: { value: _vm.form.name_01 },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "name_01", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group" }, [
            _c("span", [_vm._v("名")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.name_02,
                  expression: "form.name_02"
                }
              ],
              staticClass: "form-control size_s alert",
              class: { "alert-danger": !_vm.inputs_status.name_02 },
              attrs: {
                type: "text",
                id: "name_02",
                name: "name_02",
                role: "alert",
                placeholder: "例）太郎",
                required: "",
                "aria-label": "お名前(名)"
              },
              domProps: { value: _vm.form.name_02 },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "name_02", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "error-text mb-0" }, [
            _vm.validation_errors.name_01
              ? _c("span", { staticClass: "pl-1" }, [
                  _vm._v(_vm._s(_vm.validation_errors.name_01)),
                  _c("br")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.validation_errors.name_02
              ? _c("span", { staticClass: "pl-1" }, [
                  _vm._v(_vm._s(_vm.validation_errors.name_02))
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "mb-0 name_03 required",
            class: {
              ok: _vm.inputs_status.name_03 && _vm.inputs_status.name_04
            },
            attrs: { for: "name_03" }
          },
          [_vm._v("お名前（カナ）")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group col_name" }, [
          _c("div", { staticClass: "input-group" }, [
            _c("span", [_vm._v("セイ")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.name_03,
                  expression: "form.name_03"
                }
              ],
              staticClass: "form-control size_s alert",
              class: { "alert-danger": !_vm.inputs_status.name_03 },
              attrs: {
                type: "text",
                id: "name_03",
                name: "name_03",
                role: "alert",
                placeholder: "例）ジュクダ",
                "data-type": "katakana",
                required: "",
                "aria-label": "フリガナ(姓)"
              },
              domProps: { value: _vm.form.name_03 },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "name_03", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group" }, [
            _c("span", [_vm._v("メイ")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.name_04,
                  expression: "form.name_04"
                }
              ],
              staticClass: "form-control size_s alert",
              class: { "alert-danger": !_vm.inputs_status.name_04 },
              attrs: {
                type: "text",
                id: "name_04",
                name: "name_04",
                role: "alert",
                placeholder: "例）タロウ",
                "data-type": "katakana",
                required: "",
                "aria-label": "フリガナ(名)"
              },
              domProps: { value: _vm.form.name_04 },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "name_04", $event.target.value)
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "error-text mb-0" }, [
            _vm.validation_errors.name_03
              ? _c("span", { staticClass: "pl-1" }, [
                  _vm._v(_vm._s(_vm.validation_errors.name_03)),
                  _c("br")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.validation_errors.name_04
              ? _c("span", { staticClass: "pl-1" }, [
                  _vm._v(_vm._s(_vm.validation_errors.name_04))
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "mb-0 maile required",
            class: { ok: _vm.inputs_status.mail },
            attrs: { for: "maile" }
          },
          [_vm._v("メールアドレス")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.email,
                expression: "form.email"
              }
            ],
            staticClass: "form-control size_l alert",
            class: { "alert-danger": !_vm.inputs_status.mail },
            attrs: {
              type: "email",
              id: "maile",
              name: "mail",
              "data-type": "email",
              role: "alert",
              placeholder: "例）jiem@jyukupita.jp",
              required: ""
            },
            domProps: { value: _vm.form.email },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "email", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.validation_errors.mail
            ? _c("p", { staticClass: "error-text" }, [
                _vm._v(_vm._s(_vm.validation_errors.mail))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "mb-0 tel required",
            class: { ok: _vm.inputs_status.tel },
            attrs: { for: "tel" }
          },
          [_vm._v("携帯電話番号")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.tel,
                expression: "form.tel"
              }
            ],
            staticClass: "form-control size_l alert",
            class: { "alert-danger": !_vm.inputs_status.tel },
            attrs: {
              type: "tel",
              id: "tel",
              name: "tel",
              "data-type": "tel",
              role: "alert",
              maxlength: "11",
              placeholder: "例）08012345678",
              required: ""
            },
            domProps: { value: _vm.form.tel },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "tel", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.validation_errors.tel
            ? _c("p", { staticClass: "error-text" }, [
                _vm._v(_vm._s(_vm.validation_errors.tel))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm._m(0)
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "required-item" }, [
        _c("dl", { staticClass: "mb-0" }, [
          _vm.remain_required > 0
            ? _c("dd", { staticClass: "text-center" }, [
                _c("strong", [_vm._v("必須項目")]),
                _c("br"),
                _vm._v("残り"),
                _c(
                  "strong",
                  { staticClass: "text-danger is-text-size-xlarge" },
                  [_vm._v(_vm._s(_vm.remain_required))]
                ),
                _vm._v("個")
              ])
            : _c("dd", { staticClass: "text-left" }, [
                _vm._v("入力お疲れ様でした。"),
                _c("br"),
                _c("strong", [_vm._v("[確認画面へ]")]),
                _c("br"),
                _vm._v("ボタンを押してください。")
              ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "w-100 text-center mt-40 mb-50" }, [
        _c(
          "button",
          {
            staticClass: "btn btn_notice suggest",
            attrs: {
              id: "to-confirm1",
              type: "submit",
              disabled: !_vm.submit_active
            }
          },
          [_vm._v("確認画面へ")]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "attention" }, [
      _c(
        "p",
        { staticClass: "alert alert-primary", attrs: { role: "alert" } },
        [
          _c("i", { staticClass: "fas fa-exclamation-triangle fa-fw" }),
          _vm._v(
            "ご本人様確認のため、ショートメッセージ（SMS）で認証コード（SMS認証）をお送りします。"
          )
        ]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0f7f0a59", { render: render, staticRenderFns: staticRenderFns })
  }
}