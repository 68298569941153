var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.onLoading
    ? _c("td", [_c("label")])
    : _c("td", { class: _vm.style }, [
        _vm.completed
          ? _c("label", [_c("span", [_vm._v("予約済")])])
          : _vm.outdated
          ? _c("label")
          : _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.struct.selected,
                    expression: "struct.selected"
                  }
                ],
                attrs: { type: "checkbox", disabled: _vm.outdated },
                domProps: {
                  value: _vm.blockId,
                  checked: Array.isArray(_vm.struct.selected)
                    ? _vm._i(_vm.struct.selected, _vm.blockId) > -1
                    : _vm.struct.selected
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.struct.selected,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = _vm.blockId,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.struct, "selected", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.struct,
                            "selected",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.struct, "selected", $$c)
                    }
                  }
                }
              }),
              _vm.adjusting ? _c("span", [_vm._v("調整中")]) : _c("span")
            ])
      ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-79c40e30", { render: render, staticRenderFns: staticRenderFns })
  }
}