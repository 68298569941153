import moment from 'moment'
import { ReserveBlockConfirmStatuses, ReserveBlocks, DBPrefectures, JSPrefectures, SearchSchoolYears, SearchPurposeLists, SearchSupports, SearchFacilities, SearchRadius } from './enums'


class SignUpForm {
  constructor(){
    this.zip_code = ''
    this.address_01 = '' //prefecture
    this.address_02 = '' //city
    this.address_03 = ''
    this.name_01 = '' //姓
    this.name_02 = '' //名
    this.name_03 = '' //名kana
    this.name_04 = '' //名kana
    this.email = ''
    this.tel = ''
  }
  populate(data){
    const db_p_code = parseInt(data.prefecture_code) || 0
    const pref_name = DBPrefectures[db_p_code] || ''
    const js_prefecture_code = Object.keys(JSPrefectures).find(code => JSPrefectures[code] == pref_name)
    this.zip_code = data.zip_code
    this.address_01 = js_prefecture_code || ''
    this.address_02 = data.address1
    this.address_03 = data.address2
    this.name_01 = data.first_name
    this.name_02 = data.last_name
    this.name_03 = data.first_name_kana
    this.name_04 = data.last_name_kana
    this.email = data.email
    this.tel = data.phone_number
  }
  params(){
    const js_p_code = parseInt(this.address_01) || 0
    const pref_name = JSPrefectures[js_p_code] || ''
    const db_prefecture_code = Object.keys(DBPrefectures).find(code => DBPrefectures[code] == pref_name)
    return {
      zip_code: this.zip_code,
      prefecture_code: db_prefecture_code || 0,
      address1: this.address_02, //市区町村
      address2: this.address_03, //番号・建物名
      first_name: this.name_01, 
      last_name: this.name_02, 
      first_name_kana: this.name_03, 
      last_name_kana: this.name_04, 
      gender: 1,
      email: this.email,
      phone_number: this.tel,
    }
  }
  toPersist(){
    return {
      zip_code: this.zip_code,
      address_01: this.address_01,
      address_02: this.address_02,
      address_03: this.address_03, 
      name_01: this.name_01, 
      name_02: this.name_02, 
      name_03: this.name_03, 
      name_04: this.name_04, 
      email: this.email,
      tel: this.tel,
    }
  }
  fromPersist(data){
    this.zip_code = data.zip_code
    this.address_01 = data.address_01
    this.address_02 = data.address_02
    this.address_03 = data.address_03 
    this.name_01 = data.name_01 
    this.name_02 = data.name_02 
    this.name_03 = data.name_03 
    this.name_04 = data.name_04 
    this.email = data.email
    this.tel = data.tel
    this.school_year = data.school_year
    return this
  }
}


class SearchSchoolParams{
  constructor(){
    this.address = ''
    this.radius = 2000
    this.schoolYear = ''
    this.purposeLists = []
    this.supports = []
    this.facilities = []

    this.teachCourseGroup = true //送信時は1/keyなし
    this.teachCourseIndividual = true //送信時は1/keyなし

    this.onlineSupport = false //送信時は1/keyなし
    this.movieFlg = false //送信時は1/keyなし
    this.zoomFlg = false //送信時は1/keyなし
  }
  fill(data){
    this.address = data.address || ''
    this.radius = parseInt(data.radius) || 2000
    this.schoolYear = data.school_year || ''
    this.purposeLists = data.purpose_lists || []
    this.supports = data.supports || []
    this.facilities = data.facilities || []

    this.teachCourseGroup = Number(data.teach_course_group) === 1
    this.teachCourseIndividual = Number(data.teach_course_individual) === 1

    this.onlineSupport = Number(data.online_support) === 1
    this.movieFlg = Number(data.movie_flg) === 1
    this.zoomFlg = Number(data.zoom_flg) === 1
    if (this.onlineSupport && !this.movieFlg && !this.zoomFlg) {
      //トップから遷移してきた場合のみありえるパターン
      this.movieFlg = true
      this.zoomFlg = true
    }
  }

  get radiusText() {
    return SearchRadius[this.radius] || ''
  }
  get schoolYearText() {
    return SearchSchoolYears[this.schoolYear] || ''
  }
  get teachCourseText() {
    var list = []
    if (this.teachCourseGroup) {
      list.push('集団指導')
    }
    if (this.teachCourseIndividual) {
      list.push('個別指導')
    }
    return list.join('、')
  }
  get optionTexts(){
    let texts = []
    if (this.onlineSupport) {
      texts.push('映像授業・ライブ授業')
    }
    if (this.purposeLists) {
      texts.push(...this.purposeLists.map(val => SearchPurposeLists[val]));
    }
    if (this.supports) {
      texts.push(...this.supports.map(val => SearchSupports[val]));
    }
    if (this.facilities) {
      texts.push(...this.facilities.map(val => SearchFacilities[val]));
    }
    if (!this.onlineSupport){
      if (this.movieFlg) {
        texts.push('映像授業')
      }
      if (this.zoomFlg) {
        texts.push('双方向ライブ授業')
      }
    }
    return texts.filter(t => !!t)
  }

  copy(){
    const alt = new SearchSchoolParams()
    Object.assign(alt, this)
    return alt
  }

  validate(){
    let errors = {}
    if (!this.schoolYear) {
      errors.schoolYear = '学年を選択してください'
    }
    if (!this.teachCourseGroup && !this.teachCourseIndividual) {
      errors.schoolFormats = '授業形式を選択してください'
    }
    return errors
  }
}

class SearchLocationForm{
  constructor(){
    this.keyword = ''
    this.stationId = null
    this.address = null
    this.userLocation = null
  }
  fill(data){
    this.keyword = data.keyword || ''
    this.stationId = data.station_id || ''
    this.address = data.address || ''
    this.userLocation = data.user_location_lat ? {latitude: data.user_location_lat, longitude: data.user_location_lng} : null
  }
  validate(){
    let errors = {}
    if (!this.address && !this.stationId && !this.userLocation) {
      errors.location = '場所を入力してください'
    }
    return errors
  }
}

class ObserveUserBlock{
  constructor(data){
    this.id = parseInt(data.id) || 0
    this.observeDate = moment(data.observe_date, 'YYYY-MM-DD')
    this.blockId = parseInt(data.block_id) || 0
    this.blockConfirmStatus = parseInt(data.block_confirm_status) || 0

    this.cd = this.observeDate.format('YYYYMMDD')
  }

  isStatus(stateName){
    return this.blockConfirmStatus === ReserveBlockConfirmStatuses[stateName]
  }
}

class SchedulerDateStruct{
  constructor(date) {
    this.cd = date.format('YYYYMMDD')
    this.rowCd = date.format('YYYYMM')
    this.date = date
    this.rowSpan = 0

    this.outdated = []

    this.adjustings = [] // blockId
    this.completeds = [] // blockId

    this.selected = [] // blockId
    this.baseSelected = [] // blockId

    this.syncd = false
  }

  setOutDated(minStartAt){ //moment
    this.outdated = []
    if (this.date.isAfter(minStartAt)) {
      return
    }
    this.outdated = Object.keys(ReserveBlocks).map(id => Number(id)).filter((blockId) => {
      const blockData = ReserveBlocks[blockId]
      const startAt = moment(this.date).set(blockData.from)
      return startAt.isBefore(minStartAt)
    })
    return this
  }

  get hasActiveBlock(){
    return this.selected.filter(blockId => !this.outdated.includes(blockId)).length > 0
  }

  setStates(rowBlocks){
    this.selected = rowBlocks.filter(b => b.isStatus('Proposal') || b.isStatus('Adjusting')).map(b => b.blockId).filter(blockId => !this.outdated.includes(blockId))
    this.baseSelected = this.selected.map(i => i)
    this.completeds = rowBlocks.filter(b => b.isStatus('Complete')).map(b => b.blockId)
   
    this.syncd = true
  }

  get week() {
    return ['日', '月', '火', '水', '木', '金', '土'][this.date.day()]
  }
  
  params () {
    const getParam = (blockId, isActive) => {
      return {
        observe_date_ymd: this.date.format("YYYY-MM-DD"),
        block_id: Number(blockId) || null,
        active: isActive,
      }
    }
    const actives = this.selected.map(blockId => getParam(blockId, true))
    const deactives = this.baseSelected.filter(blockId => !this.selected.includes(blockId)).map(blockId => getParam(blockId, false))
    return actives.concat(deactives)
  }
}

class ProposalSchool{
  constructor(data){
    this.id = parseInt(data.id) || 0
    this.brandId = parseInt(data.brand_id) || 0
    this.name = data.name
    this.brandName = data.brand_name
  }
}

export default {
  SignUpForm: () => new SignUpForm(),
  SearchSchoolParams: () => new SearchSchoolParams(),
  SearchLocationForm: () => new SearchLocationForm(),
  SchedulerDateStruct: (date) => new SchedulerDateStruct(date),
  ObserveUserBlock: data => new ObserveUserBlock(data),
  ProposalSchool: data => new ProposalSchool(data)
}