import $ from 'jquery'

//Bootstrap
import 'bootstrap'

export default {
  onVueMounted: function() {
    $('[data-toggle="popover"]').popover();
    $('[data-toggle="tooltip"]').tooltip();
  },
  showModal: function(selector, ) {
    return new Promise(function(resolve) {
      const $elm = $(selector)
      $elm.off('shown.bs.modal')
      $elm.on('shown.bs.modal', function() {
        setTimeout(resolve, 300);
      })
      $elm.modal('show')
    })
  },
  hideModal: function(selector, imid = false) {
    const $elm = $(selector)
    if (imid) {
      $elm.modal('hide')
      return
    }
    return new Promise(function(resolve) {
      $elm.off('hidden.bs.modal')
      $elm.on('hidden.bs.modal', function(){
        setTimeout(resolve, 300);
      })
      $elm.modal('hide')
    })
  },
  showModalSending: function(seconds){
    return new Promise(function(resolve) {
      const $elm = $('#sending')
      const $wrap = $elm.find('.progress')
      const $bar = $elm.find('.progress-bar')
      $bar.css('transition', 'none').width(0);
      $elm.off('shown.bs.modal')
      $elm.on('shown.bs.modal', function(){
        const wrapWidth = $wrap.width() || 100
        $elm.find('.progress-bar').animate({width: wrapWidth - 10}, seconds || 4000, 'linear')
        setTimeout(resolve, 300);
      })
      $elm.modal('show')
    })
  },
  hideModalSending: function(){
    return new Promise(function(resolve) {
      const $elm = $('#sending')
      const $wrap = $elm.find('.progress')
      const $bar = $elm.find('.progress-bar')
      const wrapWidth = $wrap.width() || 100
      $bar.stop().animate({width: wrapWidth}, 300, 'linear', function(){
        setTimeout(function(){
          $elm.off('hidden.bs.modal')
          $elm.on('hidden.bs.modal', function(){
            setTimeout(resolve, 500);
          })
          $elm.modal('hide')
        }, 500);
      })
    })
  },
  schedulerFooterCtrl: function(){
    //スクロールーボタン連動処理
    var window_height, calendar_bottom, buttons_bottom;
    var $footer_button = $('.footer_button');
  
    /**
     * ボタン表示制御 変数初期化
     */
    function init () {
      window_height = window.innerHeight || $(window).height();
      calendar_bottom = $('.reserve-calendar-wrapper').eq(0).offset().top + $('.reserve-calendar-wrapper').eq(0).outerHeight();
      buttons_bottom = $('.footer_button-wrapper').eq(0).offset().top + $('.footer_button-wrapper').eq(0).outerHeight();
    }
    /**
     * ボタン表示状態の更新
     */
    function update () {
      var scrolled = $(window).scrollTop();
      $footer_button[(scrolled + window_height >= calendar_bottom)? 'addClass' : 'removeClass']('showed');
      $footer_button[(scrolled + window_height >= buttons_bottom)? 'removeClass' : 'addClass']('fixed');
    }
  
    init();
    update();
  
    $(window).on('scroll touchmove', update);
    $(window).on('resize', init);
  }
}