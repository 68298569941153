<template>
      <div class="reserve-calendar-wrapper">
          <div id="reserveCalendar" class="reserve-calendar">
              <div class="timezone">
                  <span class="calendar-head">日時</span>
                  <span>13:00〜<br>14:30</span>
                  <span>14:30〜<br>16:00</span>
                  <span>16:00〜<br>17:30</span>
                  <span>17:30〜<br>19:00</span>
                  <span>19:00〜<br>20:30</span>
                  <span>20:30〜<br>22:00</span>
              </div>
              <Candidate ref="candidate" :structs="structs" @updateCalendarScroll="updateCalendarScroll" @updateCellWidth="updateCellWidth" />
              <span class="caledanr-scrollbar" :style="caledanrScrollbarStyle"></span>
          </div>
          <button type="button" class="calendar-arrow prev" :disabled="calendarArrowPrevDisabled" @click="willCandidateScroll(true)"></button>
          <button type="button" class="calendar-arrow next" :disabled="calendarArrowNextDisabled" @click="willCandidateScroll(false)"></button>
      </div>
</template>

<script>
import $ from "jquery";
import Candidate from './ReserveCalendarWrapper/Candidate.vue'
export default {
  components: {Candidate},
  props: {
    structs: Array, //[SchedulerDateStruct]
  },
  data(){
    return {
      cellWidth: 30,　//初期値適当で可
      innerWidth: 100, //初期値適当で可
      scrollWidth: 1, //初期値適当で可
      scrolled: 0, //初期値適当で可
    }
  },
  computed: {
    barWidth(){
      return this.innerWidth * this.innerWidth / this.scrollWidth;
    },
    barLeft(){
      return this.scrolled / (this.scrollWidth - this.innerWidth) * (this.innerWidth - this.barWidth) + 60;
    },
    caledanrScrollbarStyle(){
      return {
        width: `${this.barWidth}px`,
        left: `${this.barLeft}px`,
      }
    },
    calendarArrowPrevDisabled(){
      return this.scrolled <= 0
    },
    calendarArrowNextDisabled(){
      return this.scrolled + this.innerWidth >= this.scrollWidth
    },
    moveWidth(){
      return Math.floor(this.innerWidth / this.cellWidth) * this.cellWidth
    }
  },
  methods: {
    updateCellWidth(cellWidth){
      this.cellWidth = cellWidth
    },
    updateCalendarScroll(payload){
      const {innerWidth, scrollWidth, scrolled} = payload
      this.innerWidth = innerWidth
      this.scrollWidth = scrollWidth
      this.scrolled = scrolled
    },
    willCandidateScroll(toPrev){
      $(this.$refs.candidate.$el).animate({scrollLeft: this.scrolled + (this.moveWidth * (toPrev ? -1 : 1))});
    }
  }
}
</script>

<style>

</style>