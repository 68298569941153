<template>
  <form class="is-horizontal pb-0 mb-0" novalidate @submit.prevent.stop="willUpdate">
    <!-- <div class="text-capable"><strong>Webでカンタン！<br>お気軽にどうぞ</strong></div>
    <div class="text-center" style="margin-bottom: 32px;">
      <button class="btn btn_reservelist_list" id="submit" type="submit" :disabled="!shouldUpdate">{{updateText}}</button>
    </div> -->
    <!-- <div class="campaign-banner schedule_list-top">
      <a href="/present/202106">
        <picture>
          <source media="(min-width:768px)" srcset="assets/banner_schedule_top-pc.png 1x,assets/banner_schedule_top-pc@2x.png 2x">
          <source media="(max-width:767px)" srcset="assets/banner_schedule_top-sp.png 1x,assets/banner_schedule_top-sp@2x.png 2x">
          <img src="assets/banner_schedule_top-pc.png" srcset="assets/banner_schedule_top-pc.png 1x,assets/banner_schedule_top-pc@2x.png 2x" alt="入塾お祝いキャンペーン今だけ！">
        </picture>
      </a>
      <p class="text-right campaign_nextlink"><a href="/present/202106">プレゼント条件など詳しくはこちら <img src="assets/icon_ arrow.svg" alt="" style="width: 8px;"></a></p>
    </div> -->
    <h4 class="h4-ttl ml-0">選んだ塾を予約する</h4>
    <div class="search p-0">
      <section class="search_list_section">
        <div class="search-radius school_year_area" style="align-items: baseline;">
          <label for="school_year" class="mb-0 required p-0 text-bold school_format_ttl text-center">学年</label>
          <div class="cp_ipselect cp_sl01">
            <select id="school_year" name="school_year" class="form-control alert mb-0 unselected" role="alert" required v-model="schoolYear">
              <option value="" disabled="disabled" selected>選択してください</option>
              <option :value="val" v-for="(label, val) in SchoolYears" :key="val">{{label}}</option>
            </select>
          </div>
          <table class="table table_borderUpDw sms-confirm-table first_check_list" id="check_regist_group">
            <tr v-for="sCheck in schoolChecks" :key="sCheck.id">
              <th>
                <div class="search-radius customer_info sms-confirm">
                  <div class="form-check-inline check_regist_group">
                    <input type="checkbox" class="form-check-input ml-3" :id="sCheck.name" v-model="sCheck.check" @change="saveSchoolChecks">
                    <label class="form-check-label text-left sms-check" :for="sCheck.name"><span class="f-normal"></span></label>
                  </div>
                </div>
              </th>
              <td>
                <div class="list_text">
                  <div class="jyuku-icon" v-if="sCheck.school.teach_course == true && sCheck.school.brand.teach_course07 == 1">
                  <span class="basic-M english">英語塾・英会話</span>
                  </div>
                  <div class="jyuku-icon" v-if="(sCheck.school.teach_course == false || sCheck.school.teach_course == NULL) && sCheck.school.teach_course07 == 1">
                  <span class="basic-M english">英語塾・英会話</span>
                  </div>
                  <div class="tag_area" v-if="sCheck.school.online_school_flg == 1">
                  <span class="new_tag_online text-bold">
                    <img src="/assets/icon_online@2x.png" alt="online">
                    自宅で受講できるオンライン教室
                  </span>
                  </div>
                  <p class="school_ttl"><strong>{{sCheck.school.brand.name}} {{sCheck.school.name}}</strong></p>
                  <p class="school_text" v-html="sCheck.school.reserve_service">{{sCheck.school.reserve_service}}</p>
                  <div class="attention" v-if="inValidSchoolIds.includes(sCheck.id)">
                    <p class="alert alert-primary" role="alert">
                      <i class="fas fa-exclamation-triangle fa-fw"></i>
                      <span>対象学年は{{sCheck.gradesText}}のため、予約できません。チェックを外してください。</span>
                    </p>
                  </div>
                </div>
                <a :href="`/school/${sCheck.school.brand.id}/${sCheck.id}`">詳細を見る<img src="/assets/icon_ arrow.svg" alt="" class="ml-1"></a>
              </td>
            </tr>
          </table>
        </div>
      </section>
    </div>
    <div class="campaign-banner schedule_list-bottom">
      <div class="text-capable type2"><strong>より良い塾選びのために、<br>2~3校以上の検討がおすすめ！</strong></div>
      <div class="text-center" style="margin-bottom: 32px;">
        <button class="btn btn_reservelist_list" type="submit" id="submit2" :disabled="!shouldUpdate">{{updateText}}</button>
      </div>
      <!-- <picture>
        <source media="(min-width:768px)" srcset="assets/banner_schedule_bottom-pc.png 1x,assets/banner_schedule_bottom-pc@2x.png 2x">
        <source media="(max-width:767px)" srcset="assets/banner_schedule_bottom-sp.png 1x,assets/banner_schedule_bottom-sp@2x.png 2x">
        <img src="assets/banner_schedule_bottom-pc.png" srcset="assets/banner_schedule_bottom-pc.png 1x,assets/banner_schedule_bottom-pc@2x.png 2x" alt="入塾お祝いキャンペーン今だけ！">
      </picture>
      <p class="text-center campaign_nextlink"><a href="/present/202106">プレゼント条件など詳しくはこちら <img src="assets/icon_ arrow.svg" alt="" style="width: 8px;"></a></p>
      <p class="gif mb-0"><img src="assets/arrow02.gif" alt=""></p> -->
    </div>
    <div>
      <h4 class="h4-ttl ml-0">一緒に予約する</h4>
    </div>
    <!-- <div class="text-capable type2">
      <strong>より良い塾選びのために、<br>2~3校以上の検討がおすすめ！</strong>
    </div> -->
    <div class="search-result-list">
      <!-- ここからv-for -->
      <table class="table second_check_list">
        <tr v-for="(school, index) in recommendSchools" :key="index">
          <td v-if="loopCount(index)">
            <ul class="search-result-list_inner">
              <li class="search-result-school">
                <!-- 英会話タグ -->
                <div class="jyuku-icon" v-if="school.tag08">
									<span class="cassette english">英語塾・英会話</span>
								</div>
                <!-- オンライン教室タグ -->
                <div class="tag_area" v-if="school.online_school_flg">
                  <!-- v-if="school.online_school_flg == 1"> -->
                  <span class="new_tag_online text-bold">
                    <img src="/assets/icon_online@2x.png" alt="online">
                    自宅で受講できるオンライン教室
                  </span>
                </div>
                <!-- search-result-body -->
                <div class="search-result-body">
                  <div class="school_text">
                    <!-- 校舎キャンペーンタグ -->
                    <div class="campaign" v-if="school.school_campaign || school.brand_campaign">
                      <img src="assets/icon_campaign.svg" alt="キャンペーン">
                      <p class="mb-0 text-bold">
                        <span v-if="school.brand_campaign" v-html="school.brand_campaign"><br /></span>
                        <span v-if="school.school_campaign" v-html="school.school_campaign"></span>
                      </p>
                    </div>
                    <!-- 校舎名 -->
                    <div class="text-bold school_ttl">
											<div class="school_ttl_link">
												<p>  {{ school.brand_name }} {{ school.name }} </p>
											</div>
											<figure><img :src="school.brand_img_url" alt="銘柄ロゴ画像"></figure>
										</div>
                    <!-- メッセージ01 -->
										<p class="description"> {{ school.message01 }} </p>
                    <!-- 授業形式タグ -->
										<div class="tag_list">
                      <span class="class-format" v-if="school.tag01">個別/１対多</span>
                      <span class="class-format" v-if="school.tag02">個別/１対１</span>
                      <span class="class-format" v-if="school.tag03">集団/大人数</span>
                      <span class="class-format" v-if="school.tag04">集団/少人数</span>
                      <span class="class-format" v-if="school.tag05">集団指導</span>
                      <span class="class-format" v-if="school.tag06">映像授業</span>
                      <span class="class-format" v-if="school.tag07">学習教室</span>
                      <span class="class-format" v-if="school.tag08">英会話・英語塾</span>
                      <span class="class-format" v-if="school.tag09">オンライン英会話</span>
                      <span class="class-format" v-if="school.tag10">家庭教師</span>
                      <span class="class-format" v-if="school.tag11">オンライン家庭教師</span>
                      <span class="class-format" v-if="school.tag12">通信教育</span>
                      <span class="english-features" v-if="school.tag13">マンツーマンレッスン</span>
                      <span class="english-features" v-if="school.tag14">グループレッスン</span>
                      <span class="english-features" v-if="school.tag15">英文法</span>
                      <span class="english-features" v-if="school.tag16">英検対策</span>
                      <span class="english-features" v-if="school.tag17">TOEIC対策</span>
                      <span class="english-features" v-if="school.tag18">TOEFL対策</span>
                      <span class="english-features" v-if="school.tag19">ネイティブ外国人講師</span>
                      <span class="support" v-if="school.tag20">自習室</span>
                      <span class="support" v-if="school.tag21">授業外質問OK</span>
                      <span class="support" v-if="school.tag22">振替制度</span>
                      <span class="support" v-if="school.tag23">補習授業</span>
                      <span class="support" v-if="school.tag24">定期テスト対策</span>
                      <span class="support" v-if="school.tag25">英検</span>
                      <span class="support" v-if="school.tag26">漢検</span>
                      <span class="support" v-if="school.tag27">数検</span>
                      <span class="online" v-if="school.tag28">オンライン学習あり</span>
                      <span class="facility" v-if="school.tag29">入退室管理</span>
                      <span class="facility" v-if="school.tag30">駐車場</span>
                      <span class="facility" v-if="school.tag31">送迎バス</span>
                    </div>
                  </div>
                  <!-- 校舎ロゴイメージ -->
                  <div class="school_image">
                    <figure><img :src="school.brand_img_url" alt="校舎ロゴ画像"></figure>
                  </div>
                </div><!-- /search-result-body -->
                
                <!-- search-result-footer -->
                <div class="search-result-footer">
                  <a :href="school.school_detail_url" id="go_school01" class="btn white-blue">詳細を見る</a>
                  <button id="addlist_search02" type="button" class="btn btn_reservelist_list list_in text-2line" v-if="school.online_flg" @click="addSchoolChecks(school, index)">体験予約リストに追加<span>(オンライン可)</span></button>
                  <button id="addlist_search01" type="button" class="btn btn_reservelist_list list_in" v-if="!school.online_flg" @click="addSchoolChecks(school, index)">体験予約リストに追加</button>
                </div><!-- /search-result-footer -->
                
              </li>
            </ul>
          </td>
        </tr>
        <tr v-for="(school, index) in recommendOnlineSchools" :key="index">
          <td v-if="onlineLoopCount(index)">
            <ul class="search-result-list_inner">
              <li class="search-result-school">
                <!-- 英会話タグ -->
                <div class="jyuku-icon" v-if="school.tag08">
									<span class="cassette english">英語塾・英会話</span>
								</div>
                <!-- オンライン教室タグ -->
                <div class="tag_area" v-if="school.online_school_flg">
                  <!-- v-if="school.online_school_flg == 1"> -->
                  <span class="new_tag_online text-bold">
                    <img src="/assets/icon_online@2x.png" alt="online">
                    自宅で受講できるオンライン教室
                  </span>
                </div>
                <!-- search-result-body -->
                <div class="search-result-body">
                  <div class="school_text">
                    <!-- 校舎キャンペーンタグ -->
                    <div class="campaign" v-if="school.school_campaign || school.brand_campaign">
                      <img src="assets/icon_campaign.svg" alt="キャンペーン">
                      <p class="mb-0 text-bold">
                        <span v-if="school.brand_campaign" v-html="school.brand_campaign"><br /></span>
                        <span v-if="school.school_campaign" v-html="school.school_campaign"></span>
                      </p>
                    </div>
                    <!-- 校舎名 -->
                    <div class="text-bold school_ttl">
											<div class="school_ttl_link">
												<p>  {{ school.brand_name }} {{ school.name }} </p>
											</div>
											<figure><img :src="school.brand_img_url" alt="銘柄ロゴ画像"></figure>
										</div>
                    <!-- メッセージ01 -->
										<p class="description"> {{ school.message01 }} </p>
                    <!-- 授業形式タグ -->
										<div class="tag_list">
                      <span class="class-format" v-if="school.tag01">個別/１対多</span>
                      <span class="class-format" v-if="school.tag02">個別/１対１</span>
                      <span class="class-format" v-if="school.tag03">集団/大人数</span>
                      <span class="class-format" v-if="school.tag04">集団/少人数</span>
                      <span class="class-format" v-if="school.tag05">集団指導</span>
                      <span class="class-format" v-if="school.tag06">映像授業</span>
                      <span class="class-format" v-if="school.tag07">学習教室</span>
                      <span class="class-format" v-if="school.tag08">英会話・英語塾</span>
                      <span class="class-format" v-if="school.tag09">オンライン英会話</span>
                      <span class="class-format" v-if="school.tag10">家庭教師</span>
                      <span class="class-format" v-if="school.tag11">オンライン家庭教師</span>
                      <span class="class-format" v-if="school.tag12">通信教育</span>
                      <span class="english-features" v-if="school.tag13">マンツーマンレッスン</span>
                      <span class="english-features" v-if="school.tag14">グループレッスン</span>
                      <span class="english-features" v-if="school.tag15">英文法</span>
                      <span class="english-features" v-if="school.tag16">英検対策</span>
                      <span class="english-features" v-if="school.tag17">TOEIC対策</span>
                      <span class="english-features" v-if="school.tag18">TOEFL対策</span>
                      <span class="english-features" v-if="school.tag19">ネイティブ外国人講師</span>
                      <span class="support" v-if="school.tag20">自習室</span>
                      <span class="support" v-if="school.tag21">授業外質問OK</span>
                      <span class="support" v-if="school.tag22">振替制度</span>
                      <span class="support" v-if="school.tag23">補習授業</span>
                      <span class="support" v-if="school.tag24">定期テスト対策</span>
                      <span class="support" v-if="school.tag25">英検</span>
                      <span class="support" v-if="school.tag26">漢検</span>
                      <span class="support" v-if="school.tag27">数検</span>
                      <span class="online" v-if="school.tag28">オンライン学習あり</span>
                      <span class="facility" v-if="school.tag29">入退室管理</span>
                      <span class="facility" v-if="school.tag30">駐車場</span>
                      <span class="facility" v-if="school.tag31">送迎バス</span>
                    </div>
                  </div>
                  <!-- 校舎ロゴイメージ -->
                  <div class="school_image">
                    <figure><img :src="school.brand_img_url" alt="校舎ロゴ画像"></figure>
                  </div>
                </div><!-- /search-result-body -->
                
                <!-- search-result-footer -->
                <div class="search-result-footer">
                  <a :href="school.school_detail_url" id="go_school01" class="btn white-blue">詳細を見る</a>
                  <button id="addlist_search02" type="button" class="btn btn_reservelist_list list_in text-2line" v-if="school.online_flg" @click="addSchoolChecks(school, index)">体験予約リストに追加<span>(オンライン可)</span></button>
                  <button id="addlist_search01" type="button" class="btn btn_reservelist_list list_in" v-if="!school.online_flg" @click="addSchoolChecks(school, index)">体験予約リストに追加</button>
                </div><!-- /search-result-footer -->
                
              </li>
            </ul>
          </td>
        </tr>
      </table>
      <!-- オンライン用 -->
      <table class="table second_check_list">
        
      </table>
    </div>
    <div v-if="hasRecommends">
      <slot />
      <div class="text-capable"><strong>Webでカンタン！<br>お気軽にどうぞ</strong></div>
      <div class="text-center">
        <button class="btn btn_reservelist_list" type="submit" id="submit3" :disabled="!shouldUpdate">{{updateText}}</button>
      </div>
    </div>
  </form>
</template>

<script>

import Cookies from "js-cookie";
import { SchoolYears, SchoolYears2DBSchoolGrades } from '../enums'

class SchoolCheck{
  constructor(check, school){
    this.id = school.id
    this.name = `headder_reserve_list${this.id}`
    this.check = check
    this.school = school
    if ((Number(school.grade) === 1)) {
      this.grades = Object.keys(SchoolYears).filter(grade =>  Number(school.brand[grade]) == 1)
    }else{
      this.grades = Object.keys(SchoolYears).filter(grade =>  Number(school[grade]) == 1)
    }
    this.gradesText = this.forgeGradesText()
  }
  forgeGradesText(){
    let ranges = []
    var range = null
    Object.keys(SchoolYears).forEach(val => {
      const label = SchoolYears[val]
      if (!this.grades.includes(val)) {
        range = null
        return
      }
      if (range) {
        range.push(label)
        return
      }
      range = [label]
      ranges.push(range)
    })
    return ranges.map(range => {
      if (range.length == 1) {
        return range[0]
      }
      return `${range[0]}〜${range[range.length - 1]}`
    }).join(', ')
  }
}

class RecommendSchools {
  constructor(school) {
    this.id = school.id
    this.school = school
    this.name = school.name
    this.school_detail_url = "/school/" + school.brand.id + "/" + school.id
    this.brand_name = school.brand.name
    this.online_flg = school.online_flg
    this.online_school_flg = school.online_school_flg
    const check_schools = Cookies.get("checked_school_ids").split(',')
    if(check_schools.indexOf(school.id) >= 0) {
      this.isAdded = true
    } else {
      this.isAdded = false
    }
    // キャンペーン
    if(school.school_campaign != null || school.school_campaign != ""){
      this.school_campaign = school.school_campaign
    } else {
      this.school_campaign = false
    }
    if(school.apply_brand_campaign) {
      this.brand_campaign = school.brand.campaign
    } else {
      this.brand_campaign = false
    }
    this.message01 = school.message01
    this.message02 = school.message02
    this.message03 = school.message03
    if(school.brand.logo != '') {
      this.brand_img_url = "/uploads/" + school.brand.id + "/" + school.brand.logo
    } else {
      this.brand_img_url = "/assets/mo_image.svg"
    }
    
    // 個別/1対多
    if(
      (school.teach_course && school.brand.teach_course01==1) || 
      (!school.teach_course && school.teach_course01==1)
    ) {
      this.tag01 = true
    } else {
      this.tag01 = false
    }
    // 個別/1対1
    if (
      (school.teach_course && school.brand.teach_course02==1)||
      (!school.teach_course && school.teach_course02==1)
    ) {
      this.tag02 = true
    } else {
      this.tag02 = false
    }
    // 集団/大人数
    if(
      (school.teach_course && school.brand.teach_course03==1) ||
      (!school.teach_course && school.teach_course03==1)
    ) {
      this.tag03 = true
    } else {
      this.tag03 = false
    }
    // 集団/少人数
    if(
      (school.teach_course && school.brand.teach_course04==1) ||
      (!school.teach_course && school.teach_course04==1)
    ) {
      this.tag04 = true
    } else {
      this.tag04 = false
    }
    // 集団指導
    if(
        (school.teach_course && (school.brand.teach_course03==1 || school.brand.teach_course04==1)) ||
        (!school.teach_course && (school.teach_course03==1 || school.teach_course04==1))
    ) {
      this.tag05 = true
    } else {
      this.tag05 = false
    }
    // 映像授業
    if(
      (school.teach_course && school.brand.teach_course05==1) ||
      (!school.teach_course && school.teach_course05==1)
    ) {
      this.tag06 = true
    } else {
      this.tag06 = false
    }
    // 学習教室
    if(
      (school.teach_course && school.brand.teach_course06==1) ||
      (!school.teach_course && school.teach_course06==1)
    ) {
      this.tag07 = true
    } else {
      this.tag07 = false
    }
    // 英会話・英語塾
    if(
      (school.teach_course && school.brand.teach_course07==1) ||
      (!school.teach_course && school.teach_course07==1)
    ) {
      this.tag08 = true
    } else {
      this.tag08 = false
    }
    // オンライン英会話
    if(
      (school.teach_course && school.brand.teach_course08==1) ||
      (!school.teach_course && school.teach_course08==1)
    ) {
      this.tag09 = true
    } else {
      this.tag09 = false
    }
    // 家庭教師
    if(
      (school.teach_course && school.brand.teach_course09==1) ||
      (!school.teach_course && school.teach_course09==1)
    ) {
      this.tag10 = true
    } else {
      this.tag10 = false
    }
    // オンライン家庭教師
    if(
      (school.teach_course && school.brand.teach_course10==1) ||
      (!school.teach_course && school.teach_course10==1)
    ) {
      this.tag11 = true
    } else {
      this.tag11 = false
    }
    // 通信教育
    if(
      (school.teach_course && school.brand.teach_course11==1) ||
      (!school.teach_course && school.teach_course11==1)
    ) {
      this.tag12 = true
    } else {
      this.tag12 = false
    }
    // マンツーマンレッスン
    if(school.teach_course==true){
      if(
        (school.brand.teach_course07==1 || school.brand.teach_course08==1) &&
        (school.brand.english01==1)
      ) {
        this.tag13 = true
      } else {
        this.tag13 = false
      }
    } else if(school.teach_course==false) {
      if((school.teach_course07==1 || school.teach_course08==1)) {
        if(!school.english && school.english01==1) {
          this.tag13 = true
        } else if(school.english && school.brand.english01==1){
          this.tag13 = true
        } else {
          this.tag13 = false
        }
      } else {
        this.tag13 = false
      }
    }
    // グループレッスン
    if(school.teach_course==true){
      if(
        (school.brand.teach_course07==1 || school.brand.teach_course08==1) &&
        (school.brand.english02==1)
      ) {
        this.tag14 = true
      } else {
        this.tag14 = false
      }
    } else if(school.teach_course==false) {
      if((school.teach_course07==1 || school.teach_course08==1)) {
        if(!school.english && school.english02==1) {
          this.tag14 = true
        } else if(school.english && school.brand.english02==1){
          this.tag14 = true
        } else {
          this.tag14 = false
        }
      } else {
        this.tag14 = false
      }
    }
    // 英文法
    if(school.teach_course==true){
      if(
        (school.brand.teach_course07==1 || school.brand.teach_course08==1) &&
        (school.brand.english03==1)
      ) {
        this.tag15 = true
      } else {
        this.tag15 = false
      }
    } else if(school.teach_course==false) {
      if((school.teach_course07==1 || school.teach_course08==1)) {
        if(!school.english && school.english03==1) {
          this.tag15 = true
        } else if(school.english && school.brand.english03==1){
          this.tag15 = true
        } else {
          this.tag15 = false
        }
      } else {
        this.tag15 = false
      }
    }
    // 英文対策
    if(school.teach_course==true){
      if(
        (school.brand.teach_course07==1 || school.brand.teach_course08==1) &&
        (school.brand.english04==1)
      ) {
        this.tag16 = true
      } else {
        this.tag16 = false
      }
    } else if(school.teach_course==false) {
      if((school.teach_course07==1 || school.teach_course08==1)) {
        if(!school.english && school.english04==1) {
          this.tag16 = true
        } else if(school.english && school.brand.english04==1){
          this.tag16 = true
        } else {
          this.tag16 = false
        }
      } else {
        this.tag16 = false
      }
    }
    // TOIEC対策
    if(school.teach_course==true){
      if(
        (school.brand.teach_course07==1 || school.brand.teach_course08==1) &&
        (school.brand.english05==1)
      ) {
        this.tag17 = true
      } else {
        this.tag17 = false
      }
    } else if(school.teach_course==false) {
      if((school.teach_course07==1 || school.teach_course08==1)) {
        if(!school.english && school.english05==1) {
          this.tag17 = true
        } else if(school.english && school.brand.english05==1){
          this.tag17 = true
        } else {
          this.tag17 = false
        }
      } else {
        this.tag17 = false
      }
    }
    // TOFEL対策
    if(school.teach_course==true){
      if(
        (school.brand.teach_course07==1 || school.brand.teach_course08==1) &&
        (school.brand.english06==1)
      ) {
        this.tag18 = true
      } else {
        this.tag18 = false
      }
    } else if(school.teach_course==false) {
      if((school.teach_course07==1 || school.teach_course08==1)) {
        if(!school.english && school.english06==1) {
          this.tag18 = true
        } else if(school.english && school.brand.english06==1){
          this.tag18 = true
        } else {
          this.tag18 = false
        }
      } else {
        this.tag18 = false
      }
    }
    // ネイティブ外国人講師
    if(school.teach_course==true){
      if(
        (school.brand.teach_course07==1 || school.brand.teach_course08==1) &&
        (school.brand.english07==1)
      ) {
        this.tag19 = true
      } else {
        this.tag19 = false
      }
    } else if(school.teach_course==false) {
      if((school.teach_course07==1 || school.teach_course08==1)) {
        if(!school.english && school.english07==1) {
          this.tag19 = true
        } else if(school.english && school.brand.english07==1){
          this.tag19 = true
        } else {
          this.tag19 = false
        }
      } else {
        this.tag19 = false
      }
    }
    // 自習室
    if(
      (school.support && school.brand.support01==1) ||
      (!school.support && school.support01==1)
    ) {
      this.tag20 = true
    } else {
      this.tag20 = false
    }
    // 授業外質問OK
    if(
      (school.support && school.brand.support02==1) ||
      (!school.support && school.support02==1)
    ) {
      this.tag21 = true
    } else {
      this.tag21 = false
    }
    // 振替制度
    if(
      (school.support && school.brand.support03==1) ||
      (!school.support && school.support03==1)
    ) {
      this.tag22 = true
    } else {
      this.tag22 = false
    }
    // 補修授業
    if(
      (school.support && school.brand.support04==1) ||
      (!school.support && school.support04==1)
    ) {
      this.tag23 = true
    } else {
      this.tag23 = false
    }
    // 定期テスト対策
    if(
      (school.support && school.brand.support05==1) ||
      (!school.support && school.support05==1)
    ) {
      this.tag24 = true
    } else {
      this.tag24 = false
    }
    // 英検
    if(
      (school.support && school.brand.support06==1) ||
      (!school.support && school.support06==1)
    ) {
      this.tag25 = true
    } else {
      this.tag25 = false
    }
    // 漢検
    if(
      (school.support && school.brand.support07==1) ||
      (!school.support && school.support07==1)
    ) {
      this.tag26 = true
    } else {
      this.tag26 = false
    }
    // 数検
    if(
      (school.support && school.brand.support08==1) ||
      (!school.support && school.support08==1)
    ) {
      this.tag27 = true
    } else {
      this.tag27 = false
    }
    // オンライン学習あり
    if(
      (school.brand.zoom_flg==true) || (school.brand.movie_flg==true)
    ) {
      this.tag28 = true
    } else {
      this.tag28 = false
    }
    // 入退室管理
    if(
      (school.facility && school.brand.facility01==1) ||
      (!school.facility && school.facility01==1)
    ) {
      this.tag29 = true
    } else {
      this.tag29 = false
    }
    // 駐車場
    if(
      (school.facility && school.brand.facility02==1) ||
      (!school.facility && school.facility02==1)
    ) {
      this.tag30 = true
    } else {
      this.tag30 = false
    }
    // 送迎バス
    if(
      (school.facility && school.brand.facility03==1) ||
      (!school.facility && school.facility03==1)
    ) {
      this.tag31 = true
    } else {
      this.tag31 = false
    }
  }
}

export default {
  props: ['hasCustomerInfo', 'hasRecommendsFlag', 'onlyOnline'],
  data(){
    return {
      schoolYear: '',
      schoolChecks: [],
      recommendSchools: [],
      recommendOnlineSchools: [],
      errors: {},
      onlyOnline: false
    }
  },
  computed: {
    SchoolYears: () => SchoolYears,
    inValidSchoolIds(){
      if (!this.schoolYear) {
        return []
      }
      return this.schoolChecks.filter((sCheck) => sCheck.check && !sCheck.grades.includes(this.schoolYear)).map((sCheck) => sCheck.id)
    },
    checkedSchoolIds() {
      return this.schoolChecks.filter(sCheck => sCheck.check).map(sc => sc.id)
    },
    shouldUpdate(){
      return this.schoolYear && this.checkedSchoolIds.length && this.inValidSchoolIds.length == 0
    },
    updateText(){
      if (!this.checkedSchoolIds.length) {
        return '塾を選択してください'
      }
      if (Number(this.hasCustomerInfo) === 1) {
        return '無料体験予約へ進む'
      }
      return  '無料体験予約へ進む'
    },
    hasRecommends(){
      return Number(this.hasRecommendsFlag) === 1
    },
    loopCount() {
      return function (index) {
        if(!Cookies.get('searched_school_ids')) {
          if(this.onlyOnline && index < 5){
            return true
          } else if(!this.onlyOnline && index < 4){
            return true
          }
          return false
        } else {
          if(index < 5) {
            return true
          }
          return false
        }
      }
    },
    onlineLoopCount() {
      return function (index) {
        if(this.onlyOnline) {
          return false
        } else if(!Cookies.get('searched_school_ids') && !this.onlyOnline && index < 4){
          return true
        }
      }
    }
  },
  mounted(){
    console.log('resetcelist.mounted')
    console.log(window.recommend_schools)
    const searchedYear = window.school_year || ''
    this.schoolYear = (searchedYear in SchoolYears) ? searchedYear : ''
    this.schoolChecks = (window.schools || []).map((s) => new SchoolCheck(true, s))
    this.recommendSchools = (window.recommend_schools || []).map((s) => new RecommendSchools(s))
    this.recommendOnlineSchools = (window.recommend_online_schools || []).filter(
      (s) => { 
        if(s.online_school_flg){ 
          return true 
        } 
        return false}
    ).map((s) => new RecommendSchools(s))
    this.onlyOnline = window.only_online
    console.log(this.recommendSchools)
    console.log(this.recommendOnlineSchools)
    console.log(this.onlyOnline)
  },
  methods: {
    willUpdate(){
      console.log('willUpdate')
      if (!this.shouldUpdate) {
        return false;
      }
      const grade = SchoolYears2DBSchoolGrades[this.schoolYear] || 1001
      Cookies.set("selected_school_grade_key", grade);
      this.saveSchoolChecks();
      window.location.href = '/proposal/scheduler'
      return false
    },
    saveSchoolChecks(){
      console.log('saveSchoolChecks')
      Cookies.set("checked_school_ids", this.checkedSchoolIds.join(","));
      console.log(Cookies.get("checked_school_ids"))
    },
    addSchoolChecks(school, index) {
      console.log('addSchoolChecks')
      const add_id = Cookies.get("checked_school_ids") + "," + school.id
      console.log("cheched_school_ids:" + add_id)
      Cookies.set("checked_school_ids", add_id)
      this.schoolChecks.push(new SchoolCheck(true, school.school))
      console.log(index)
      if(!this.onlyOnline && !Cookies.get('searched_school_ids') && school.online_school_flg) {
        this.recommendOnlineSchools.splice(index, 1)    
      } else {
        this.recommendSchools.splice(index, 1)
      }
      console.log(this.recommendSchools);
      console.log(this.recommendOnlineSchools);
      this.scrollTop()
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }
}
</script>

<style>

</style>
