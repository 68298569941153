export const ReserveBlockConfirmStatuses = {
  Proposal: 0,
  Adjusting: 1,
  Complete: 2,
}
export const ReserveBlocks = {
    13001430: {from: {hour: 13, minute: 0}, to: {hour: 14, minute: 30}},
    14301600: {from: {hour: 14, minute: 30}, to: {hour: 16, minute: 0}},
    16001730: {from: {hour: 16, minute: 0}, to: {hour: 17, minute: 30}},
    17301900: {from: {hour: 17, minute: 30}, to: {hour: 19, minute: 0}},
    19002030: {from: {hour: 19, minute: 0}, to: {hour: 20, minute: 30}},
    20302200: {from: {hour: 20, minute: 30}, to: {hour: 22, minute: 0}}
}
export class InvalidError extends Error {
  constructor (code) {
    super('Invalid')
    this.code = code
  }
}
export class ForbiddenError extends InvalidError {
  constructor () {
    super('ログアウトしました')
    this.name = this.constructor.name
  }
}

export const DBSchoolGrades = {
  1001: "幼児",
  2001: "小学1年生",
  2002: "小学2年生",
  2003: "小学3年生",
  2004: "小学4年生",
  2005: "小学5年生",
  2006: "小学6年生",
  3001: "中学1年生",
  3002: "中学2年生",
  3003: "中学3年生",
  4001: "高校1年生",
  4002: "高校2年生",
  4003: "高校3年生",
  5001: "浪人"
}
export const SchoolYears2DBSchoolGrades = {
  grade01:1001,
  grade02:2001,
  grade03:2002,
  grade04:2003,
  grade05:2004,
  grade06:2005,
  grade07:2006,
  grade08:3001,
  grade09:3002,
  grade10:3003,
  grade11:4001,
  grade12:4002,
  grade13:4003,
  grade14:5001,
}
export const SchoolYears = {
  grade01: '幼児',
  grade02: '小学1年生',
  grade03: '小学2年生',
  grade04: '小学3年生',
  grade05: '小学4年生',
  grade06: '小学5年生',
  grade07: '小学6年生',
  grade08: '中学1年生',
  grade09: '中学2年生',
  grade10: '中学3年生',
  grade11: '高校1年生',
  grade12: '高校2年生',
  grade13: '高校3年生',
  grade14: '浪人',
}
export const SearchSchoolYears = {
  grade01: "幼児",
  grade02: "小学1年生",
  grade03: "小学2年生",
  grade04: "小学3年生",
  grade05: "小学4年生",
  grade06: "小学5年生",
  grade07: "小学6年生",
  small: "小学生すべて",
  grade08: "中学1年生",
  grade09: "中学2年生",
  grade10: "中学3年生",
  middle: "中学生すべて",
  grade11: "高校1年生",
  grade12: "高校2年生",
  grade13: "高校3年生",
  high: "高校生すべて",
  grade14: "浪人",
  all: "すべて",
}
export const SearchRadius = {
  500: '500m',
  700: '700m',
  1000: '1km',
  2000: '2km',
  3000: '3km',
  5000: '5km',
}
export const SearchPurposeLists = {
  purpose01: "小学受験",
  purpose0203: "中学受験",
  purpose04: "高校受験",
  purpose050607: "大学受験",
  purpose08: "学校授業中心",
  purpose09: "自立型学習",
  purpose10: "内部進学",
}
export const SearchSupports = {
  support01: "自習室",
  support02: "授業外質問OK",
  support03: "少人数クラス",
  support04: "振替制度",
  support05: "補習授業あり",
  support06: "定期テスト対策あり",
  support07: "英検サポート",
}
export const SearchFacilities = {
  facility01: "入退室管理",
  facility02: "駐車場",
  facility03: "送迎バス",
}
export const JSPrefectures = {
  1:"北海道",
  2:"青森県",
  3:"岩手県",
  4:"宮城県",
  5:"秋田県",
  6:"山形県",
  7:"福島県",
  8:"茨城県",
  9:"栃木県",
  10:"群馬県",
  11:"埼玉県",
  12:"千葉県",
  13:"東京都",
  14:"神奈川県",
  15:"新潟県",
  16:"富山県",
  17:"石川県",
  18:"福井県",
  19:"山梨県",
  20:"長野県",
  21:"岐阜県",
  22:"静岡県",
  23:"愛知県",
  24:"三重県",
  25:"滋賀県",
  26:"京都府",
  27:"大阪府",
  28:"兵庫県",
  29:"奈良県",
  30:"和歌山県",
  31:"鳥取県",
  32:"島根県",
  33:"岡山県",
  34:"広島県",
  35:"山口県",
  36:"徳島県",
  37:"香川県",
  38:"愛媛県",
  39:"高知県",
  40:"福岡県",
  41:"佐賀県",
  42:"長崎県",
  43:"熊本県",
  44:"大分県",
  45:"宮崎県",
  46:"鹿児島県",
  47:"沖縄県",
}
export const DBPrefectures = {
  1:"東京都",
  2:"埼玉県",
  3:"神奈川県",
  4:"千葉県",
  5:"茨城県",
  6:"群馬県",
  7:"栃木県",
  8:"山梨県",
  9:"北海道",
  10:"青森県",
  11:"秋田県",
  12:"岩手県",
  13:"山形県",
  14:"宮城県",
  15:"福島県",
  16:"愛知県",
  17:"岐阜県",
  18:"三重県",
  19:"静岡県",
  20:"新潟県",
  21:"長野県",
  22:"富山県",
  23:"石川県",
  24:"福井県",
  25:"大阪府",
  26:"兵庫県",
  27:"京都府",
  28:"滋賀県",
  29:"奈良県",
  30:"和歌山県",
  31:"愛媛県",
  32:"香川県",
  33:"高知県",
  34:"徳島県",
  35:"広島県",
  36:"岡山県",
  37:"山口県",
  38:"鳥取県",
  39:"島根県",
  40:"佐賀県",
  41:"福岡県",
  42:"長崎県",
  43:"宮崎県",
  44:"熊本県",
  45:"大分県",
  46:"鹿児島県",
  47:"沖縄県"
}