
class Input {
  constructor (element) {
    this.element = element;


    /**
     * 入力要素 name属性値
     * @type {String}
     */
    this.name = this.element.name.replace(/\[\]$/, '');
    
    /**
     * 入力項目名（日本語） エラーメッセージで使用
     * @type {String}
     */
    this.input_name = null;
    if(this.element.getAttribute('aria-label')){
      this.input_name = this.element.getAttribute('aria-label');
    }else if(document.querySelector('label[for="' + this.element.id + '"]')){
      this.input_name = document.querySelector('label[for="' + this.element.id + '"]').innerText.replace(/\(.+\)/,'');
    }

    /**
     * 入力値エラー文言
     * @type {String}
     */
    this.error = null;

    /**
     * 入力必須
     * @type {Boolean}
     */
    this.required = this.element.getAttribute('required') || false;

    /**
     * 入力値型
     * @type {String}
     */
    this.type = 'text';

    /**
     * フォーカス済みフラグ
     * @type {boolean}
     */
    this.focused = false;


    if(this.element.type === 'radio') {
      this.type = 'radio';
    } else if(this.element.tagName.toLowerCase() === 'select') {
      this.type = 'select';
    } else if(this.element.type === 'checkbox') {
      this.type = 'checkbox';
    } else if(this.element.type === 'date') {
      // Androidではカレンダー表示から選択するために、UXが悪いので使用しないことになった
      this.type = 'date';
    } else if(this.element.dataset && this.element.dataset.type === 'ymd') {
      // 年月日を分離している
      this.type = 'ymd';
      this.name = this.name.match(/^([a-zA-Z]+)_(year|month|date)$/)[1];
      this.year = document.querySelector('[name=' + this.name + '_year]');
      this.month = document.querySelector('[name=' + this.name + '_month]');
      this.date = document.querySelector('[name=' + this.name + '_date]');

    } else if(this.element.dataset && this.element.dataset.type) {
      this.type = this.element.dataset.type;
    }

    if(this.element.dataset && this.element.dataset.condition) {
      /**
       * 入力値条件
       *  past:過去（生年月日）
       *  now:現在時刻
       * @type {String}
       */
      this.condition = this.element.dataset.condition;
    }


    /**
     * 入力値の一致を確認する対象要素
     * name属性
     * @type {DOMElement}
     */
    this.match = null;

    if(this.element.dataset && this.element.dataset.match) {
      this.match = document.querySelector('[name=' + this.element.dataset.match + ']');
    }


    this.element.addEventListener('focus', ()=>{ this.focused = true; });
    this.element.addEventListener('change', ()=>{ this.focused = true; });
  }

  /**
   * バリデーション
   */
  validate () {
    /**
     * 日付要素の検証用オブジェクト
     */
    let input_date = new Date();
    let now = new Date();

    // 入力必須
    if(this.required) {
      if(this.getValue === null || this.getValue === ''){
        if(this.type === 'radio' || this.type === 'select' || this.type === 'checkbox') {
          this.error = (this.input_name? this.input_name+'は':'') + '選択必須です';
        } else {
          this.error = (this.input_name? this.input_name+'は':'') + '入力必須です';
        }
        return false;
      } else if(this.type === 'checkbox' && this.getValue.length === 0) {
        // 複数選択肢のあるチェックボックス（どれか一個以上にチェックが必要）
        this.error = 'いずれかを選択してください';
        return false;
      }
    } else if (this.getValue === null || this.getValue === '') {
      return true;
    }



    // 確認用入力エリア
    if(this.match && this.getValue !== this.match.value) {
      this.error = '入力内容が一致しません';
      return false;
    }

    // 日付の入力値確認
    switch(this.type) {
      case 'katakana':
        if(!this.getValue.match(/^[ァ-ヶー　\s]+$/)) {
          this.error = 'カタカナで名前を入力してください';
          return false;
        }
        break;
      // 日付
      // AndroidでUXがまずくて使用しない
      case 'date':
        // 入力形式が間違い
        if(!this.getValue.match(/^[0-9]{8}$/)) {
          this.error = '正しく入力してください';
          return false;
        }

        input_date = new Date(this.getValue.substr(0,4)+'/'+this.getValue.substr(4,2)+ '/' + this.getValue.substr(6,2));

        // 入力値は過去であるかどうか確認
        if(this.condition === 'past' && input_date.getTime() >= now.getTime()) {
          this.error = '正しい日付を入力してください';
          return false;
        }
        break;
      // 年月日（input要素分離）
      case 'ymd':
        if(this.year.value === null || this.year.value === '' || this.month.value === null || this.month.value === '' || this.date.value === null || this.date.value === '') {
          this.error = '入力必須です';
          return false;
        }

        input_date = new Date(this.year.value + '/' + this.month.value + '/' + this.date.value);

        if(Number(this.month.value) !== input_date.getMonth() + 1) {
          // 存在しない日付を指定
          this.error = '日付を正しく入力してください。';
          return;
        }

        if(this.condition === 'past' && input_date.getTime() >= now.getTime()) {
          this.error = '正しい日付を入力してください';
          return false;
        }
        break;
      case 'password':
        if(this.getValue.length < 8) {
          this.error = '8文字以上で入力してください';
          return false;
        } else if (this.getValue.length > 128) {
          this.error = '128文字以下で入力してください';
          return false;
        }
        if(!this.getValue.match(/[a-z]/) || !this.getValue.match(/[A-Z]/) || !this.getValue.match(/[0-9]/)) {
          this.error = '半角数字、半角英字の大文字・小文字をそれぞれ１文字以上含めてください';
          return false;
        }
        break;
      case 'email':
        if(!this.getValue.match(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/)){
          this.error = 'メールアドレスの形式で入力してください';
          return false;
        }
        break;
      //　電話番号
      case 'tel':
        if(!this.getValue.match(/^(090|080|070)\-?[0-9]{4}\-?[0-9]{4}$/)){
          this.error = '正しい携帯電話の番号を入力してください';
          return false;
        }
        break;
      //　郵便番号
      case 'zip':
        if(!this.getValue.match(/^[0-9]{3}\-?[0-9]{4}$/)){
          this.error = '正しい郵便番号を入力してください';
          return false;
        }
        break;
      case 'smscode':
        let code_size = Number(this.element.getAttribute('maxlength') || 4);
        if(this.getValue.length !== code_size || !this.getValue.match(/^[0-9]+$/)) {
          this.error = code_size + '桁の番号を入力してください';
          return false;
        }
        break;
    }


    this.error = null;
    return true;
  }

  /**
   * Boolean文字列をBoolean型に変換
   * その他の値はそのまま
   */
  toBoolean (value) {
    if(typeof value !== 'string'){
      return value;
    } else if(value.toLowerCase() === 'true'){
      return true;
    } else if (value.toLowerCase() === 'false') {
      return false;
    } else {
      return value;
    }
  }

  /**
   * 入力ガイドを表示　点滅させる
   */
  setRequired (onoff = false) { 
    let sync_element = [...document.querySelectorAll('[name=' + this.element.name + ']')];
    
    if(sync_element.length > 1){
      // 連動するinput要素がある場合
      sync_element.forEach((ele)=>{
        if(onoff) {
          ele.setAttribute('data-nextrequired', '');
        } else {
          ele.removeAttribute('data-nextrequired');
        }
      });
    } else {
      
      if(onoff) {
        this.element.setAttribute('data-nextrequired', '');
      } else {
        this.element.removeAttribute('data-nextrequired');
      }
    }
  }

  /**
   * 入力値の出力
   * 必要に応じて値を加工
   */
  get getValue () {

    if(this.condition === 'now') {
      return new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2) + '-' + ('0' + new Date().getDate()).slice(-2) + ' ' + ('0' + new Date().getHours()).slice(-2) + ':' + ('0' + new Date().getMinutes()).slice(-2) + ':' + ('0' + new Date().getSeconds()).slice(-2);
    }

    switch(this.type) {
      // ラジオボタン
      case 'radio':
        if(document.querySelector('input[name=' + this.name + ']:checked')) {
          return document.querySelector('input[name=' + this.name + ']:checked').value;
        } else {
          return null;
        }
        break;

      // 日付
      // AndroidでUXがまずくて使用しないことになった
      case 'date':
        return this.element.value.replace(/\-/g, '');
        break;

      // 年月日分離
      case 'ymd':
        return this.year.value + ('0' + this.month.value).slice(-2) + ('0' + this.date.value).slice(-2);
        break;

      // チェックボックス
      case 'checkbox':
        let values = [];

        let checkboxes = this.element.form[this.name] || this.element.form[this.name + '[]']
        if(checkboxes.length > 1) {
          // 複数の要素がある場合は配列に格納
          [...checkboxes].forEach((el)=>{
            if(el.checked) {
              values.push(el.value);
            }
          });
        } else {
          // 要素が一個の場合
          values = this.toBoolean((this.element.checked)? this.element.value : null);
        }
        return values;
        break;
      case 'zip':
        this.element.value = this.element.value.replace('-','');
        return this.element.value;
        break;
      case 'smscode':
        let code_size = Number(this.element.getAttribute('maxlength') || 4);
        if(String(this.element.value).length > code_size){
          this.element.value = Number(String(this.element.value).slice(0, code_size));
        }
        return this.element.value;
        break;
      default:
        return this.element.value;
        break;
    }
  }
}

const efoFormMixIn = {
  data(){
    return {
      /**
       * Inputインスタンスを格納
       */
      inputs: [],

      /**
       * Inputの入力可否状況
       */
      inputs_status: {},

      /**
       * ステップごとのInputインスタンスの配列を格納
       */
      steps: {},

      current_step: 0,



      /**
       * 残り必須項目数
       */
      remain_required: 0,

      /**
       * submit後のユーザー操作ロックフラグ
       */
      locked: false,
      refresh_flag: false,

      /**
       * バリデーションエラー文言
       */
      validation_errors: {},

      /**
       * 次の入力項目ガイド（要素点滅）有無フラグ
       */
      nextrequired_flag: false
    }
  },
  mounted () {
    console.log('sfoForm mounted')
    this.nextrequired_flag = this.$el.dataset.nextrequiredFlag;

    this.initialize();
  },
  updated () {
    // フォームDOMが非同期に更新されたとき（APIから取得したデータを基にinput要素を生成するなど）
    // 入力要素オブジェクトを再初期化する必要がある
    if(this.refresh_flag) {
      this.refresh_flag = false;
      this.initialize();
    }
  },
  computed: {
    form_class () {
      return {
        '-lock': this.locked
      }
    },
    /**
     * サブミットボタンの活性状態
     */
    submit_active () {
      return this.remain_required === 0 && Object.keys(this.validation_errors).length === 0;
    }
  },
  methods: {
    /**
     * 入力要素オブジェクトの初期化
     */
    initialize () {
      this.inputs = [];
      this.steps = {};

      if(this.$el.querySelectorAll('[data-form-step]').length > 0) {
        // ステップのある場合
        [...this.$el.querySelectorAll('[data-form-step]')].forEach((step_element) => {

          this.steps[step_element.dataset.formStep] = this.getInputsInstance([...step_element.querySelectorAll('input, select')]);
          this.inputs = this.inputs.concat(this.steps[step_element.dataset.formStep]);
        });
      } else {
        this.inputs = this.getInputsInstance([...this.$el.elements]);
      }

      this.updateStatus();

      //戻り後の再送信可能に
      history.replaceState(null, document.getElementsByTagName('title')[0].innerHTML, null);
      window.addEventListener('popstate', function(e) {
        this.locked = false
      });
    },
    /**
     * 残り必須項目数、入力状態を再計算
     * 各項目がchangeされるごとに実行
     */
    updateStatus () {
      this.remain_required = 0;

      this.inputs.forEach((input)=> {
        if(!input.validate()) {
          if(this.remain_required === 0 && this.nextrequired_flag) {
            // 入力すべき最初の項目
            input.setRequired(true);
            //input.element.setAttribute('data-nextrequired', '');
          } else {
            //input.element.removeAttribute('data-nextrequired');
            input.setRequired(false);
          }
          this.inputs_status[input.name] = false;
          if(input.required){
            this.remain_required += 1;
          }
          if(input.focused) {
            if(!this.validation_errors[input.name]) {
              this.validation_errors[input.name] = '';
            }
            //this.validation_errors[input.name] = input.error;
            this.$set(this.validation_errors, input.name, input.error);
          }
        } else {

          //input.element.removeAttribute('data-nextrequired');
          input.setRequired(false);

          this.inputs_status[input.name] = true;
          if(this.validation_errors[input.name]) {
            delete this.validation_errors[input.name];
          }
        }
      });

    },
    /**
     * 入力要素インスタンスの生成
     * @param {array} elements 入力要素の配列
     * @return {array} inputインスタンスの配列
     */
    getInputsInstance (elements) {
      /**
       * 入力要素のname属性
       * ラジオボタン、チェックボックスの重複登録防止
       * @type {array}
       */
      let input_names = [];


      /**
       * 入力要素インスタンスの格納
       */
      let inputs = [];
      elements.forEach((item) => {
        if(item.name && !item.disabled){
          if(item.type === 'radio' || item.type === 'checkbox') {
            // ラジオボタン/チェックボックスは一つのオブジェクトにまとめる
            if(input_names.indexOf(item.name) === -1) {
              inputs.push(new Input(item));
              input_names.push(item.name);
            }
          } else if (item.dataset && item.dataset.type === 'ymd') {
            // 日付選択要素は一つのオブジェクトにまとめる
            let date_name = item.name.match(/^([a-zA-Z]+)_(year|month|date)$/)[1];
            if(input_names.indexOf(date_name) === -1) {
              inputs.push(new Input(item));
              input_names.push(date_name);
            }
          } else {
            inputs.push(new Input(item));
          }

          input_names.push(this.name);
        }
      });

      return inputs;
    },
    /**
     * 次のDOM再描画時に入力要素オブジェクトを再初期化させるためのフラグを立てる
     */
    refreshInputs () {
      this.refresh_flag = true;
    },
    /**
     * 入力値のバリデーション
     * @param {Boolean} passive 静的フラグ（エラー文言表示の更新をするか）デフォルトではしない
     * @param {String} step_name バリデート対象
     */
    validate (passive = false, step_name = null) {
      /**
       * input要素ごとにエラーメッセージを格納
       * @type {Object}
       */
      let errors = {};

      /**
       * バリデートするInputインスタンスの配列
       */
      let input_list = this.inputs;

      if(typeof step_name === 'string' && this.steps[step_name]) {
        input_list = this.steps[step_name];
      }

      input_list.forEach((item) => {
        this.inputs_status[item.name] = item.validate();
        if(!this.inputs_status[item.name]){
          errors[item.name] = item.error;
        }
      });


      if(Object.keys(errors).length > 0) {
        this.locked = false;
        this.errorValidation(errors);
        return false;
      } else {
        this.passValidation();
        return true;
      }
    },
    /**
     * ステップの次へ実行
     * @param {String} step_name バリデート対象
     */
    checkStep (step_name) {
      if(!step_name || !this.steps[step_name]) {
        console.error('invalid step name:', step_name);
        return false;
      }

      if(this.validate(step_name)) {
        this.$emit('step:next');
      }
    },
    /**
     * formのsubmit時に実行
     * バリデートして問題なければAPIアクセス
     */
    postData (event) {
      if(this.locked) {
        event.preventDefault();
        return false;
      }

      if(!this.validate()){
        event.preventDefault();

        // 不備のある項目の先頭にフォーカスを当てる
        let item = this.inputs.find((input)=> {return input.error;});
        item.element.focus();
        //console.log('NG', this.validation_errors);

      } else {
        this.locked = true;
      }

      console.log('submit!', event);
    },
    /**
     * バリデーション通過時
     * エラーオブジェクトを初期化
     */
    passValidation () {
      this.validation_errors = {};
    },
    /**
     * バリデーションエラー時に、エラー情報を格納
     * @param {Object} エラー情報：input要素のname属性値をキーにした連想配列、要素はエラー文言
     */
    errorValidation (errors) {
      this.validation_errors = errors;
    },
  }
}

export default {
  EfoForm: efoFormMixIn
}