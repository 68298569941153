var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "login_code",
      attrs: {
        novalidate: "",
        "data-validate": "",
        "data-nextrequired-flag": "1"
      },
      on: { submit: _vm.willUpdate, change: _vm.updateStatus }
    },
    [
      _c(
        "section",
        { staticClass: "customer_info sms-confirm ml-auto mr-auto mb-0" },
        [
          _c("h4", { staticClass: "h4-ttl ml-0" }, [
            _vm._v("認証コードを入力")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "mb-4" }, [
            _vm._v(
              "SMS（ショートメッセージ）で受け取った認証コード（4桁の番号）を入力してください。"
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group pl-0 pr-0 mb-0" }, [
            _c(
              "label",
              {
                staticClass: "mb-0 tel required",
                class: { ok: _vm.inputs_status.smscode },
                attrs: { for: "sms-code" }
              },
              [_vm._v("認証コード")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.smscode,
                  expression: "smscode"
                }
              ],
              staticClass: "form-control alert",
              class: { "alert-danger": !_vm.inputs_status.smscode },
              attrs: {
                type: "tel",
                "data-type": "smscode",
                name: "smscode",
                maxlength: "4",
                id: "sms-code",
                required: ""
              },
              domProps: { value: _vm.smscode },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.smscode = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _vm.validation_errors.smscode
              ? _c("p", { staticClass: "error-text" }, [
                  _vm._v(_vm._s(_vm.validation_errors.smscode))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-info mt-3" }, [
            _c(
              "button",
              {
                staticClass: "sms_modal",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.showModal("#modal2")
                  }
                }
              },
              [
                _c("i", {
                  staticClass: "fas fa-exclamation-triangle fa-fw mr-1"
                }),
                _vm._v("SMSが届かない場合")
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w-100 text-center mt-40 mb-10" }, [
            _c(
              "button",
              {
                staticClass: "btn suggest btn_login",
                attrs: { disabled: !_vm.submit_active }
              },
              [_vm._v("認証する")]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-df60a33e", { render: render, staticRenderFns: staticRenderFns })
  }
}